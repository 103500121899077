import React, { Component } from "react";
import Slider from "react-slick";
import carousel2 from './images/resource/causes-carousel11.jpg'
// import carousel3 from './images/resource/causes-carousel3.jpg'
// import small3 from './images/resource/causes-carousel3-small.jpg'
import carousel1 from './images/resource/causes-carousel6.jpg'
import small from './images/resource/causes-carousel10-small.jpg'
import small2 from './images/resource/causes-carousel5-small.jpg'
import carousel8 from './images/resource/causes-carousel8-small.jpg'
import small4 from './images/resource/causes-carousel4-small.jpg'
import small7 from './images/resource/causes-carousel7-small.jpg'
import carousel9 from './images/resource/causes-carousel9.jpg'
import { Link } from 'react-router-dom'


export default class AsNavFor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }
    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
        window.scrollTo(0, 0)
    }

    render() {
        //        var creativeNavs = {
        //     slidesToShow: 3,
        //     slidesToScroll: 1,
        //     // asNavFor: causesCarousel,
        //     autoplay: false,
        //     arrows: false,
        //     vertical: true,
        //     focusOnSelect: true,
        //     dots: false,
        // };
        return (
            <div>
                <section>
                    <div className="block block-mobi remove-gap" style={{ marginTop: '30px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="title">
                                        <span>Help The Homeless People</span>
                                        <h2><i>fundraising</i> campaigns</h2>
                                    </div>
                                    <div className="recent-causes">
                                        <Slider
                                            asNavFor={this.state.nav2}
                                            ref={slider => (this.slider1 = slider)}
                                            slidesToShow={1}
                                            slidesToScroll={1}
                                            // asNavFor: {...creativeNavs},
                                            autoplay={true}
                                            arrows={false}
                                            fade={true}
                                            infinite={true}
                                            dots={false}
                                            speed={2000}
                                            className="causes-carousel" >
                                            <div className="cause-img">
                                                {/* <Link to="/EventsNine"> */}
                                                    <div className="cause-bg"><img src={carousel1} alt="" />
                                                    </div>
                                                    <div className="cause-inner">

                                                        <img src={small} alt=""
                                                            style={{ width: '503px' }} />
                                                        <div className="cause-text">
                                                            <span>UREGENTLY NEEDED DONATION</span>
                                                            <h4><i>Education</i> To Every Child</h4>
                                                            <p>Akoda.org supports education for underprivileged children. Fundraising through crowd funding is an 
                                                                ideal way to support this noble cause. With the help of generous donors, Akoda has been instrumental in
                                                                 bringing a positive change to thousands of lives.</p>
                                                            <Link to="/donate-now">	<button type="button" className="default-btn mob-But" style={{ color: '#fff' }}>
                                                                Donate Now
                                                            </button></Link>
                                                        </div>
                                                    </div>
                                                {/* </Link> */}
                                            </div>
                                            <div className="cause-img">
                                                {/* <Link to="/EventsTwo"> */}
                                                    <div className="cause-bg"><img src={carousel8} alt="" />
                                                    </div>
                                                    <div className="cause-inner">

                                                        <img src={small4} alt=""
                                                            style={{ width: '503px' }} />
                                                        <div className="cause-text">
                                                            <span>UREGENTLY NEEDED DONATION</span>
                                                            <h4>Helping <i>Orphans</i></h4>
                                                            <p>The best paths to empowering children are through good health and education. 
                                                                Let’s support the next generation through fundraising using crowd funding and donating to the cause.</p>
                                                            <Link to="/donate-now">	<button type="button" className="default-btn mob-But" style={{ color: '#fff' }} >
                                                                Donate Now
                                                            </button></Link>
                                                        </div>
                                                    </div>
                                                {/* </Link> */}
                                            </div>
                                            <div className="cause-img">
                                                {/* <Link to="/EventsEleven"> */}
                                                    <div className="cause-bg"><img src={carousel9} alt="" />
                                                    </div>
                                                    <div className="cause-inner">

                                                        <img src={small7} alt=""
                                                            style={{ width: '503px' }} />
                                                        <div className="cause-text">
                                                            <span>UREGENTLY NEEDED DONATION</span>
                                                            <h4>Food For <i>Hungry</i></h4>
                                                            <p>You can be an active participant in this noble initiative too by contributing to this fundraising 
                                                                campaign and also by sharing this with family and friends, who can further strengthen this crusade with their support.</p>
                                                            <Link to="/donate-now">	<button type="button" className="default-btn mob-But" style={{ color: '#fff' }}>
                                                                Donate Now
                                                            </button></Link>
                                                        </div>
                                                    </div>
                                                {/* </Link> */}
                                            </div>
                                            <div className="cause-img">
                                                {/* <Link to="/EventsTen"> */}
                                                    <div className="cause-bg"><img src={carousel2} alt="" />
                                                    </div>
                                                    <div className="cause-inner">

                                                        <img src={small2} alt=""
                                                            style={{ width: '503px' }} />
                                                        <div className="cause-text">
                                                            <span>UREGENTLY NEEDED DONATION</span>
                                                            <h4><i>Medical</i> Treatment</h4>
                                                            <p>The best paths to empowerment children isthrough good health and education.
                                                                Let’s support the next generation through fundraising using
                                                                crowdfunding and donating for the cause. </p>
                                                            <Link to="/donate-now">	<button type="button" className="default-btn mob-But" style={{ color: '#fff' }} >	Donate Now 	</button></Link>
                                                        </div>
                                                    </div>
                                                {/* </Link> */}
                                            </div>
                                        </Slider>

                                        <Slider
                                            asNavFor={this.state.nav1}
                                            ref={slider => (this.slider2 = slider)}
                                            focusOnSelect={true}
                                            slidesToShow={3}
                                            slidesToScroll={1}
                                            autoplay={true}
                                            arrows={false}
                                            vertical={true}
                                            dots={false}
                                            speed={2000}
                                            style={{ fontWeight: '900', left: '-10%', height: '135px!important' }}
                                            className="creative-navs" >
                                            <span className="slick-current">01</span>
                                            <span>02</span>
                                            <span>03</span>
                                            <span>04</span>

                                        </Slider>
                                    </div>

                                </div>
                                {/* <!-- Creative Portfolio --> */}
                            </div>
                        </div>
                    </div>

                </section >
            </div>
        );
    }
}