import React, { useState, useRef } from 'react'

import emailjs from '@emailjs/browser'


const DonateNowForm = () => {
    { window.scrollTo({ top: 0, behavior: 'smooth' }); };
    const form = useRef();

    const sendEmail = (event) => {
        event.preventDefault();

        emailjs.sendForm('service_cdszuor', 'template_1u3263l', form.current, 'QDT9q3KiW_w51CvX3')
            .then((result) => {
                console.log(result.text);
                toastFunction();
            }, (error) => {
                console.log(error.text);
            });
    };
    function toastFunction(e) {
        e.preventDefault();
        var x = document.getElementById("toast");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 1000);
        document.getElementById("myForm").reset();
    }

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [phone, setphone] = useState('');
    const [amount, setamount] = useState('');
    const handleSubmit = (e) => {

        e.preventDefault();
        if (name === "") {
            alert("please enter details below");
        }
        else if (email === "") {
            alert("please enter details below");
        }
        else if (phone === "") {
            alert("please enter details below");
        }

        else if (amount === "") {
            alert("please enter details below");
        } else {

            var options = {
                key: "rzp_live_HMAt9sBOZL0FtW",
                key_secret: "QCRWPtWXPaEhduk7M8MdVQ7t",

                amount: amount * 100,
                currency: "INR",

                captured: true,
                payment_capture: '1',
                name: "Akoda_sk_Foundation",

                handler: function (response) {
                    // alert(response.razorpay_payment_id + "payment Successfull");
                    alert("Thanks for Donation \n Your Payment has been  successfully completed");

                },
                prefill: {
                    name: name,
                    email: email,
                    contact: phone,

                },
                notes: {
                    address: "Akoda SK Foundation"
                },
                theme: {
                    color: "#3399cc"
                }
            };


            var pay = new window.Razorpay(options);
            pay.open();
        }
    }

    return (
        <div>
            <div className="container-fluid" style={{ backgroundColor: '#efefef' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mx-auto ">
                            <div className="donatepg text-center">
                                <form ref={form} onSubmit={(event) => { sendEmail(event); }} className="donate-form" >
                                    <h2 className="fs-title">Personal Details</h2>
                                    <h3 className="fs-subtitle">Tell us something more about you</h3>
                                    <input type="text" className="form-donate" id="name" name="user_name" placeholder="Name" value={name} onChange={(e) => setname(e.target.value)} required />
                                    <input type="mail" className="form-donate" id="email" name="user_email" placeholder="Email" value={email} onChange={(e) => setemail(e.target.value)} required />
                                    <input type="number" className="form-donate" id="phone" name="user_phone" placeholder="Phone" value={phone} onChange={(e) => setphone(e.target.value)} required />
                                    <input type="number" placeholder='Enter Amount' name="user_amount" className="form-donate" value={amount} onChange={(e) => setamount(e.target.value)} />
                                    <button type="submit" className="donate-bnt" onClick={(e) => { handleSubmit(e); toastFunction(); }}>submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DonateNowForm