import React from 'react'

import event7 from '../Component/EventImages/7.png'
import siren from './assets/images/events/siren.png'
import GirlEducation from '../Component/EventImages/GirlEducation.jpg'
import facebook from './assets/images/events/facebook.png'
import { Link } from 'react-router-dom'


const EventsSeven = () => {

    { window.scrollTo({ top: 0, behavior: 'smooth' }); };
    return (
        <div>
        

            <div className="section-title section-title-left" >
                <img src={siren} style={{ width: '30px', height: '30px' }} alt="Image" />
                <span className="top-title">This fundraiser is in an urgent need of funds</span><br />
                <span className="container" style={{ color: 'rgb(17, 15, 15)', fontSize: '32px' }}>
                    Girls' Education. </span>
            </div>


            {/* <!-- Start Event Details Page Area --> */}

            <section className="evet-details-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="event-details-content ">
                                <div className="event-details-img">
                                    <img src={event7} alt="Image" />
                                    <span className="date">17 <br /> Jul</span>
                                </div>

                                <div className="event-top-content">
                                    <div className="event-content" style={{textAlign:'justify'}}>
                                       
                                      
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div className="tab-content">
                                                    <h4>Description</h4>
                                                    <p>Female education is the need of the hour. Without educating the women of the country we can’t hope for a developed nation. Women play of vital Role in the all-around progress of a country. If we want to make democracy successful, Women must be educated. They are the real builders of happy homes.</p>
                                                    <p>It is said that if we educate a man, we educate a man only, but if we educate a woman, we educate the whole family and this highlights the importance of female education</p>
                                                </div>
                                            </div>
                                          
                                        </div>


                                  



                                        <h3>Social development</h3>
                                        <p>educating women would be the key to remove many social evil of India society like dowry system, female infanticide, workplace harassment, democratic or sexual violence etc. and educated women Changes the future generations</p>



                                        <div className="gap-mb-20"></div>

                                        {/* <h3>Event mission</h3> */}
                                        <p>High living standard – and educated women will contribute financially for the needs of her family and relatives to earning can provide better growth prospects for the children as well as a raised living standard of the farming.</p>
                                        <p>Self – Reliance – education is vital For women as it makes women become self-reliant eliminates need to depend on a third person for her and her family‘s survival.
                                            Improved health and hygiene- and educated women recognise the health hazard to her family and knows how to deal with them, She knows How to feed and nature her children, telling them about good and bad hygiene.</p>

                                        <p>An educated woman is like a magic wand which brings property, health and pride. We just have to unleash her potential and see the magic happen. We have improved a lot on women education since our independence, but still a lot remains too improved. Factors restricting the growth of women education in India are mainly societal, and we need to recognise and eliminate them, if we want to achieve the goals of social- economic development
                                        </p>

                                        <div className="gap-mb-20"></div>

                                       
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-4">
                            <div className="widget-sidebar ml-15">
                                <div className="sidebar-widget mt-0" >
                                    <form className="choose-us-from-bg">


                                        <div className="others-options" style={{ padding: '2px', paddingBottom: '37px' }}>
                                            <ul>
                                                <li>
                                                    <Link to="/donate-now" className="default-btn border-radius-btn"
                                                        style={{ padding: '15px', fontSize: '20px', marginTop: '-25px', borderRadius: '0px' }}>
                                                        Donate Now
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <a href="https://www.facebook.com/profile.php?id=100079026490521" target="_blank">
                                            <div className="others-options"
                                                style={{ border: '2px solid #344E86', padding: '18px 15px 15px 15px', borderRadius: '10px', backgroundColor: '#344E86', paddingLeft: '43px' }}>
                                                <img src={facebook} height="25px" width="25px" alt="" />
                                                <span
                                                    style={{ fontWeight: 'bold', color: 'rgb(255, 248, 248)', paddingLeft: '10px' }}>Spread The Word</span>
                                            </div>
                                        </a>
                                       

                                       

                                    </form>
                                </div>

                                <div className="text-center">
                                <img src={GirlEducation}  alt="" style={{height:'300px',textAlign:'center'}} />
                                </div>
                            

                                <div className="sidebar-widget">
                                    <h3>Event location</h3>

                                    <div className="map">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.390700423798!2d78.3803008153709!3d17.441004405846062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e1bef1173f%3A0xedf7c46288fdf31a!2sKTC%20Illumination%2C%20Vittal%20Rao%20Nagar%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1663236626782!5m2!1sen!2sin"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </div>
    )
}

export default EventsSeven