import React from 'react'
import Slider from 'react-slick'
import team1 from './assets/images/volunteers/team-1.jpg'
import team2 from './assets/images/volunteers/team-2.jpg'
import team3 from './assets/images/volunteers/team-3.jpg'
import team4 from './assets/images/volunteers/team-4.jpg'
const Volunteer = () => {
	var volunteersSlider = {
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		autoplay: false,
		infinite: true,
		arrows: false,
		dots: false,


		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					autoplay: false,

				}
			},

			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					autoplay: true,
					dots: false,
					arrows: false
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false,
					autoplay: true,
					arrows: false,
					autoplaySpeed: 3000,
					infinite: true,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					autoplay: true,
					autoplaySpeed: 3000,
					infinite: true,
				}
			}
		]
	};
	return (
		<div>
			<section className="volunteers-area volunteers-bg-area pt-100 pb-70 ">
				<div className="container">
					<div className="section-title">
						<span className="top-title">Our <span style={{color:'#F08821'}}> Team</span></span>
						<h2>Our volunteers who are always going through the service</h2>
					</div>

					<div className="row volunteers-slider justify-content-center">
						<Slider {...volunteersSlider}>
							<div className="col-lg-3 col-sm-6">
								<div className="single-volunteers">
									<img src={team1} alt="Image" />

									<div className="volunteer-content">
										<h3>Kranthi</h3>
										{/* <span>Volunteer</span> */}

										<ul>
											<li>
												<a href="https://www.facebook.com/" target="_blank">
													<i className="ri-facebook-fill"></i>
												</a>
											</li>
											<li>
												<a href="https://twitter.com/" target="_blank">
													<i className="ri-twitter-line"></i>
												</a>
											</li>
											<li>
												<a href="https://www.linkedin.com/" target="_blank">
													<i className="ri-linkedin-fill"></i>
												</a>
											</li>
											<li>
												<a href="https://www.instagram.com/" target="_blank">
													<i className="ri-instagram-line"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="col-lg-3 col-sm-6">
								<div className="single-volunteers">
									<img src={team2} alt="Image" />

									<div className="volunteer-content">
										<h3>Satish</h3>
										{/* <span>Volunteer</span> */}

										<ul>
											<li>
												<a href="https://www.facebook.com/" target="_blank">
													<i className="ri-facebook-fill"></i>
												</a>
											</li>
											<li>
												<a href="https://twitter.com/" target="_blank">
													<i className="ri-twitter-line"></i>
												</a>
											</li>
											<li>
												<a href="https://www.linkedin.com/" target="_blank">
													<i className="ri-linkedin-fill"></i>
												</a>
											</li>
											<li>
												<a href="https://www.instagram.com/" target="_blank">
													<i className="ri-instagram-line"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="col-lg-3 col-sm-6">
								<div className="single-volunteers">
									<img src={team3} alt="Image" />

									<div className="volunteer-content">
										<h3>Imran</h3>
										<span>Volunteer</span>

										<ul>
											<li>
												<a href="https://www.facebook.com/" target="_blank">
													<i className="ri-facebook-fill"></i>
												</a>
											</li>
											<li>
												<a href="https://twitter.com/" target="_blank">
													<i className="ri-twitter-line"></i>
												</a>
											</li>
											<li>
												<a href="https://www.linkedin.com/" target="_blank">
													<i className="ri-linkedin-fill"></i>
												</a>
											</li>
											<li>
												<a href="https://www.instagram.com/" target="_blank">
													<i className="ri-instagram-line"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="col-lg-3 col-sm-6">
								<div className="single-volunteers">
									<img src={team4} alt="Image" />

									<div className="volunteer-content">
										<h3>Umesh</h3>
										<span>Volunteer</span>

										<ul>
											<li>
												<a href="https://www.facebook.com/" target="_blank">
													<i className="ri-facebook-fill"></i>
												</a>
											</li>
											<li>
												<a href="https://twitter.com/" target="_blank">
													<i className="ri-twitter-line"></i>
												</a>
											</li>
											<li>
												<a href="https://www.linkedin.com/" target="_blank">
													<i className="ri-linkedin-fill"></i>
												</a>
											</li>
											<li>
												<a href="https://www.instagram.com/" target="_blank">
													<i className="ri-instagram-line"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</Slider>
					</div>
				</div>
			</section>


		</div>
	)
}

export default Volunteer