import React from 'react'
import hire from './img/hire.jpg';

const Careers = () => {
    { window.scrollTo({ top: 0, behavior: 'smooth' }); };
    return (
        <div>
      

            <section className="our-mission-area pt-100">
                <div className="container">
                    <div className="section-title section-white-title section-title-three">
                        <span className="top-title" style={{ fontSize:'40px'}}>WE ARE HIRING </span>

                        {/* <h2 style={{ color: "#000" }}>
                            Departments
                        </h2> */}
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 ">
                            <div className="col-lg-12 col-md-12 " style={{marginTop:'-10px'}}>
                                <div className="single-mission style-box"  >
                                    {/* <img src={ei6} alt="Image" width="70" /> */}
                                    <span>01</span> <h4>Admin Executives</h4>
                                    <h3>positions  2 openings</h3>
                                    <p>
                                        <ul>
                                            <li>Qualification: Inter and Any Degree</li>
                                            <li>Job Locations: Hyderabad, India</li>
                                            <li>Required Experience: 0 - 2 Years</li>
                                            <li>Languages:English, Hindi,Telugu</li>

                                        </ul>

                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 " style={{marginTop:'-20px'}} >
                                <div className="single-mission style-box"  >
                                    {/* <img src={ei7} alt="Image" width="70" /> */}
                                    <span>02</span> <h4>Account Assistants</h4>
                                    <h3>positions  2 openings</h3>
                                    <p>
                                        <p>
                                            <ul>
                                                <li>Qualification: Inter and Any Degree</li>
                                                <li>Job Locations: Hyderabad, India</li>
                                                <li>Required Experience: 0 - 2 Years</li>
                                                <li>Languages:English, Hindi,Telugu</li>
                                            </ul>

                                        </p>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 " style={{marginTop:'-20px'}}>
                                <div className="single-mission style-box" >
                                    {/* <img src={ei8} alt="Image" width="70" /> */}
                                    <span>03</span> <h4>Volunteers</h4>
                                    <h3>positions  5 openings</h3>
                                    <p>
                                        <ul>
                                            <li>Qualification: Inter and Any Degree</li>
                                            <li>Job Locations: Hyderabad, India</li>
                                            <li>Required Experience: 0 - 2 Years</li>
                                            <li>Languages:English, Hindi,Telugu</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 " style={{marginTop:'-20px'}}>
                                <div className="single-mission style-box" >
                                    {/* <img src={ei9} alt="Image" width="70" /> */}
                                    <span>04</span><h4>Tele callers</h4>
                                    <h3>positions  5 openings</h3>
                                    <p>
                                        <ul>
                                            <li>Qualification: Inter and Any Degree</li>
                                            <li>Job Locations: Hyderabad, India</li>
                                            <li>Required Experience: 0 - 2 Years</li>
                                            <li>Languages:English, Hindi,Telugu</li>
                                        </ul>
                                    </p>
                                </div>


                            </div>
                            <div className="col-lg-12 col-md-12 " style={{marginTop:'-20px'}} >
                                <div className="single-mission style-box" >
                                    {/* <img src={ei9} alt="Image" width="70" /> */}
                                    <span>05</span><h4>HR Admin</h4>
                                    <h3>positions  1 openings</h3>
                                    <p>
                                        <ul>
                                            <li>Qualification: Inter and Any Degree</li>
                                            <li>Job Locations: Hyderabad, India</li>
                                            <li>Required Experience: 0 - 2 Years</li>
                                            <li>Languages:English, Hindi,Telugu</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-6 col-md-6 ">
                            {/* <section className="about-area about-style-three about-us-mobile ptb-100"> */}
                            {/* <div className="container"> */}
                            {/* <div className="row align-items-center obu"> */}


                            <div className="col-lg-12">
                                <div className="about-content page-style ml-15" style={{ padding: '0rem 2.5rem 3.5rem'}}>
                                    <div className="text-center">
                                        <span className="top-title" style={{ fontSize: '30px' }} >Build Your Career</span>
                                    </div>


                                    <p>Akoda Offers Endless Opportunities. </p>

                                    <p>It is our daily goal to make this world a better place.We are committed to bringing a change, and we welcome anyone who shares our passion.</p>


                                    <p>Drop Your Resume To: info@akoda.org</p>

                                    <ul>
                                        <li>
                                            <i className="ri-check-line"></i>
                                            Admin Executives
                                        </li>
                                        <li>
                                            <i className="ri-check-line"></i>
                                            Account Assistants
                                        </li>
                                        <li>
                                            <i className="ri-check-line"></i>
                                            Volunteers
                                        </li>
                                        <li>
                                            <i className="ri-check-line"></i>
                                            Tele callers
                                        </li>
                                        <li>
                                            <i className="ri-check-line"></i>
                                            HR Admin
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-lg-12 service-serv" >
                                <div className="about-img mr-15  service-serv">
                                    <img src={hire} alt="Image" style={{height:'auto', padding: '0px!important' }} />
                                </div>
                            </div>
                            {/* </div> */}
                            {/* </div> */}
                            {/* </section> */}

                            

                        </div>
                    </div>
                </div>
            </section>


      



        </div>
    )
}

export default Careers