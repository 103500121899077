import React, { useRef , useState} from 'react'
import emailjs from '@emailjs/browser'
import { Link } from 'react-router-dom';
import axios from 'axios'
import { Helmet } from "react-helmet";


const ContactUs = () => {
	{ window.scrollTo({ top: 0, behavior: 'smooth' }); };
     const [userFullName, setUserFullName] = useState("");
	 const [userEmail, setUserEmail] = useState("");
     const [userPhone, setUserPhone] = useState("");
     const [userSubject, setUserSubject] = useState("");
     const [usermessage, setUsermessage] = useState("");





	 const onChangeUserFullName=(event)=>{
		setUserFullName(event.target.value)
	 }
	 
	 const onChangeUserEmail=(event)=>{
		setUserEmail(event.target.value)
	 }
	 
	 const onChangeUserPhone=(event)=>{
		setUserPhone(event.target.value)
	 }
	 const onChangeUserSubject=(event)=>{
		setUserSubject(event.target.value)
	 }
	 const onChangeUsermessage=(event)=>{
		setUsermessage(event.target.value)
	 }

  const onCreatePost=(event)=>{
    event.preventDefault()
	const postData ={
		userFullName,
		userEmail,
		userPhone,
		userSubject,
		usermessage,
		
	}
     axios.post('http://localhost:4000/app/signup',postData,)
	 .then((response)=>{
		console.log(response);
		
	 })
	};
  

	const form = useRef();

	const sendEmail = (event) => {
		event.preventDefault();

		emailjs.sendForm('service_cdszuor', 'template_1u3263l', form.current, 'QDT9q3KiW_w51CvX3')
			.then((result) => {
				console.log(result.text);
				toastFunction();
			}, (error) => {
				console.log(error.text);
			});
	};
	function toastFunction() {
        var x = document.getElementById("toast");
        x.className = "show";
        setTimeout(function(){ x.className = x.className.replace("show", ""); }, 1000);
		document.getElementById("myForm").reset();
      }
	return (
		<div>
			<Helmet>
        <title>
		contact-us |Akoda SK foundation |Akoda fundraisers |Crowdfunding campaign in Hyderabad | contact for Fundraising| Crowdfunding | Fundraising | Best Crowdfunding Platform | Donate || Hyderabad | India  
        </title>
        <meta
          name="description"
          content="Get in touch with Akoda Fundraising."
        />
        <meta
          name="keywords"
          content="Akoda sk foundation ,Akodafundraisers, contact for Fundraising in Hyderabad telangana ,Crowdfunding campaign, contact for crowdfunding Hyderabad telangana, Fundraising in hyderabad, Best Crowdfunding Platform in Hyderabad telangana, Other Social services in Hyderabad  ,  Fundraising Campaign,  Hyderabad, India  "
        />
      </Helmet>
		
			<div className="page-title-area bg-2">
				<div className="container">
					<div className="page-title-content">
						<h2 style={{color:'#f08821'}}> Contact Us </h2>

						<ul>
							<li>
								<Link to="/Events" style={{ color: '#fff' }}>
									Save a child every month
								</Link>
							</li>


						</ul>
						<Link to="/donate-now" className="default-btn " style={{ color: '#fff', marginTop: '45px' }}>
							 Donate Now
						</Link>
					</div>
				</div>
			</div>

			<div className="contact-info-area pt-100">
				<div className="container">
					<div className="contact-info-bg">
						<div className="row justify-content-center">
							<div className="col-lg-4 col-md-6">
								<div className="single-contact-info">
									<i className="ri-map-pin-2-fill"></i>
									<div className="call-us">
										<p>Vittal Rao Nagar,<br /> KTC Illumination <br /> Opposite Westin Hotel, mindspace <br /> Hyderabad, 500081,India</p>
									</div>
								</div>
							</div>

							<div className="col-lg-4 col-md-6">
								<div className="single-contact-info">
									<i className="ri-phone-fill"></i>
									<a href="#" className="call-us">
										+91 9642383838
										<br /><br />
										<br /><br />
									</a>
									{/* <a href="#" className="call-us">
										+91 9303646464
										<br /><br />
									</a> */}
								</div>
							</div>

							<div className="col-lg-4 col-md-6">
								<div className="single-contact-info">
									<i className="ri-mail-fill"></i>
									<a href="#" className="call-us">
										<span className="__cf_email__" data-cfemail="3a525f5656557a565f4f5e14595557">info@akoda.org</span>

									</a>
									<br />
									<br/>
									{/* <a href="#" className="call-us">
										<span className="__cf_email__" data-cfemail="0f666169604f636a7a6b216c6062">contact@akoda.com</span>
									</a> */}
									<br />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="main-contact-area ptb-100">
				<div className="container">
					{/* <form id="contactForm" noValidate="true"> */}
						<div className="row">
							<div className="col-lg-6 view-form">
								<h3>Get in touch with us</h3>
								
							
									<form ref={form} onSubmit={(event) => { onCreatePost(event); sendEmail(event);}} className="form-group tp" id ="myForm">
										<label >Your name</label>
										<input type="text" name="user_name"  className="form-control fp" onChange={onChangeUserFullName} value={userFullName}   required />
										<label>Email</label>
										<input type="email" name="user_email"className="form-control fp" onChange={onChangeUserEmail} value={userEmail}  required />
										<label>Phone Number</label>
										<input type="number" name="user_phone" className="form-control fp" onChange={onChangeUserPhone} value={userPhone} required />
										<label>Subject</label>
										<input type="text" name="user_country" className="form-control fp" onChange={onChangeUserSubject} value={userSubject} required />
										<label>Message</label>
										<textarea  name="message" className="form-control fp"  cols="30" rows="8" onChange={onChangeUsermessage} value={usermessage} required></textarea>
										<button type="submit" value="Send" className="default-btn tp"  onClick={() => 'toastFunction'} >Subscribe Now </button>
										<div id="toast">
										<div className="checkicon"> <i className="fas fa-check-square"></i> </div>
											SUCCESS: We have received your registration. Thanks!</div>
									</form>



								
							</div>

							<div className="col-lg-6">
								<div className="maps-area">
									<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30450.78806156894!2d78.38086585!3d17.443025849999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e0f08bfbb3%3A0x2454332c527d3f1a!2sHyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1653564012737!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
								</div>
							</div>
						</div>
					{/* </form> */}
				</div>
			</div>

		

		</div>
	)
}

export default ContactUs