import React from 'react'

import event9 from '../Component/EventImages/9.png'
import siren from './assets/images/events/siren.png'

import facebook from './assets/images/events/facebook.png'
import { Link } from 'react-router-dom'

import importanceOfGirlsEducation from '../Component/EventImages/importanceOfGirlsEducation.jpg'
const EventsNine = () => {

    { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>


            <div className="section-title section-title-left" >
                <img src={siren} style={{ width: '30px', height: '30px' }} alt="Image" />
                <span className="top-title">This fundraiser is in an urgent need of funds</span><br />
                <span className="container" style={{ color: 'rgb(17, 15, 15)', fontSize: '32px' }}>
                Ensuring equal access to education …</span>
            </div>


            {/* <!-- Start Event Details Page Area --> */}
            
            <section className="evet-details-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="event-details-content ">
                                <div className="event-details-img">
                                    <img src={event9} alt="Image" />
                                    <span className="date">09 <br /> Mar</span>
                                </div>

                                <div className="event-top-content">
                                    <div className="event-content">
                                        
                                        <div className="tab-content" id="myTabContent" style={{textAlign:'justify'}}>
                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div className="tab-content">
                                                    <h4>Help educate girls and build a knowledge-powered society</h4>
                                                    <p>It is said that when a man is educated, only he is educated 
                                                        but when the woman of the house is educated, the entire
                                                         household is educated. Hence it is critical that every girl 
                                                         in the society is completes her journey of education. 
                                                         The social stigma towards the girl being educated, 
                                                         especially in the rural belt and the economically underprivileged
                                                          strata of the society, need to removed through enlightenment. 
                                                          If they are unable to afford the educational expenses then contributing
                                                           through donations is a best path ahead.</p>
                                                </div>
                                                <p>Crowdfunding is the easiest path, where each one contributes as per his/her ability 
                                            to donate for the cause of the girl child. But the outcome of such an initiative will 
                                            be generally overwhelming, supporting the noble cause effectively. Hence choose the 
                                            best crowdfunding platform in Telangana to be an active participant in the campaign 
                                            for girls’ education, which the best way to build a well-educated and healthy society.</p>
                                            </div>
                                            
                                            
                                        </div>


                                        <div className="gap-mb-20"></div>

                                       
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-4">
                            <div className="widget-sidebar ml-15">
                                <div className="sidebar-widget mt-0" >
                                    <form className="choose-us-from-bg">


                                        <div className="others-options" style={{ padding: '2px', paddingBottom: '37px' }}>
                                            <ul>
                                                <li>
                                                    <Link to="/donate-now" className="default-btn border-radius-btn"
                                                        style={{ padding: '15px', fontSize: '20px', marginTop: '-25px', borderRadius: '0px' }}>
                                                        Donate Now
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <a href="https://www.facebook.com/profile.php?id=100079026490521" target="_blank">
                                            <div className="others-options"
                                                style={{ border: '2px solid #344E86', padding: '18px 15px 15px 15px', borderRadius: '10px', backgroundColor: '#344E86', paddingLeft: '43px' }}>
                                                <img src={facebook} height="25px" width="25px" alt="" />
                                                <span
                                                    style={{ fontWeight: 'bold', color: 'rgb(255, 248, 248)', paddingLeft: '10px' }}>Spread The Word</span>
                                            </div>
                                        </a>


                                    </form>
                                </div>

                                <div className="text-center" >
                                <img src={importanceOfGirlsEducation} alt="" style={{height:'300px',textAlign:'center'}}/>
                                </div>


                                <div className="sidebar-widget">
                                    <h3>Event location</h3>

                                    <div className="map">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.390700423798!2d78.3803008153709!3d17.441004405846062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e1bef1173f%3A0xedf7c46288fdf31a!2sKTC%20Illumination%2C%20Vittal%20Rao%20Nagar%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1663236626782!5m2!1sen!2sin"></iframe>
                                         </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- End Even     t Details Page Area --> */}

        </div>
  )
}

export default EventsNine