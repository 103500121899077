import React, { useRef ,useState } from 'react'
import emailjs from '@emailjs/browser'
import axios from 'axios'

const Subscribe = () => {
const [userEmail, setUserEmail] = useState("");
   
    const onChangeUserEmail=(event)=>{
       setUserEmail(event.target.value)
    }
 const onCreatePost=(event)=>{
   event.preventDefault()
   const postData ={
      userEmail,
   
       
   }
    axios.post('http://localhost:4000/app/signup',postData,)
    .then((response)=>{
       console.log(response);
       document.getElementById("myForm").reset( );
       event.userEmail.value="";
       
    })
  
   };
 


    const form = useRef();

    const sendEmail = (event) => {
        event.preventDefault();

        emailjs.sendForm('service_cdszuor', 'template_1u3263l', form.current, 'QDT9q3KiW_w51CvX3')
            .then((result) => {
                console.log(result.text);
                toastFunction()
               
            }, (error) => {
                console.log(error.text);
            });
    };
    function toastFunction() {
        var x = document.getElementById("toast");
        x.className = "show";
        setTimeout(function(){ x.className = x.className.replace("show", ""); }, 1000);
        document.getElementById("myForm").reset();
      }
    return (
        <div>{/* <!-- Start Subscribe Area --> */}
            <section className="subscribe-area " >
                <div className="container">
                    <div className="bg-colo-f4f9ef">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="subscribe-content">
                                    <h2 >Subscribe to us get updated news & tips in your inbox</h2>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                {/* <form className="newsletter-form" data-toggle="validator">
                            <input type="email" name="user_email" className="form-control" placeholder="Your email address" 
                                required autoComplete="off" />
                            <div className="text-center">
                                <input className="default-btn" type="submit" value="send"/> 
                            </div>
                            <div id="validator-newsletter" className="form-result"></div>
                        </form> */}
                                <form ref={form} onSubmit={(event) => { sendEmail(event)}} className="newsletter-form" id ="myForm">
                                {/* <form ref={form} onSubmit="sendEmail(event)" className="newsletter-form" id ="myForm"> */}

                                    {/* <label>Email</label> */}
                                    <input type="email" name="user_email"  className="form-control" placeholder="Email"  onChange={onChangeUserEmail} value={userEmail} required />

                                    {/* <label>Message</label>
                                              <textarea name="message" /> */}
                                    <button type="submit" value="Send" id="myInput" className="default-btn" onclick={toastFunction} >Subscribe Now </button>
                                    <div id="toast">
                                        <div className="checkicon"> <i className="fas fa-check-square"></i> </div>
                                    SUCCESS: We have received your registration. Thanks!</div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Subscribe Area --> */}</div>
    )
}

export default Subscribe