import React from 'react'

import medical_kits_event from '../Component/EventImages/medical_kits_event.jpeg'
// import siren from './assets/images/events/siren.png'
import savePlants from '../Component/EventImages/savePlants.jpg'
import facebook from './assets/images/events/facebook.png'
import { Link } from 'react-router-dom'

const MedicalKitEvent = () => {
    { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>
   

    <div className="section-title section-title-left" >
        {/* <img src={siren} style={{ width: '30px', height: '30px' }} alt="Image" /> */}
        {/* <span className="top-title">This fundraiser is in an urgent need of funds</span><br /> */}
        <span className="container" style={{ color: 'rgb(17, 15, 15)', fontSize: '32px' }}>
       Food Donation Event </span>
    </div>


    {/* <!-- Start Event Details Page Area --> */}
    
    <section className="evet-details-area pt-100 pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="event-details-content ">
                        <div className="event-details-img">
                            <img src={medical_kits_event} alt="Image" />
                            <span className="date">12 <br /> Dec</span>
                        </div>

                        <div className="event-top-content">
                            <div className="event-content" style={{textAlign:'justify'}}>
                                {/* <ul className="admin">
                                    <li>
                                        <i className="ri-time-line"></i>
                                        09:00 AM - 12:00 PM
                                    </li>

                                    <li>
                                        <i className="ri-map-pin-2-line"></i>
                                        4502 Oakway lane woodland hills, CA 91303
                                    </li>
                                </ul> */}

                                {/* <!-- :::::::::::tab system-starts::::::::::: --> */}


                                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active tab-title" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">History</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link tab-title" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Vision</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link tab-title" id="contact-tab" data-bs-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Goals</a>
                                    </li>
                                </ul> */}
                                {/* <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div className="tab-content">
                                            <h4>Description</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                Dignissimos, accusantium autem blanditiis recusandae ratione
                                                architecto alias veniam nesciunt animi praesentium perferendis
                                                iure dolorum nihil error ad, facere optio magni tenetur
                                                molestiae eaque odit. Fugiat corporis, tempore molestias eum
                                                suscipit laudantium culpa non unde dolor aut, quas aperiam illum
                                                ullam ratione.</p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"> <div className="tab-content">
                                        <h4>Vision</h4>
                                        <p>Vel, ea praesentium sapiente excepturi consequuntur hic
                                            aspernatur, temporibus delectus dolores numquam ratione vero
                                            itaque ducimus ipsam adipisci beatae blanditiis corrupti
                                            veritatis?Lorem ipsum dolor sit amet consectetur adipisicing
                                            elit. Necessitatibus culpa molestiae quaerat nemo. </p>
                                    </div></div>
                                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                        <div className="tab-content" >
                                            <h4>Goals</h4>
                                            <p>Necessitatibus culpa molestiae quaerat nemo.Vel, ea praesentium
                                                sapiente excepturi consequuntur hic aspernatur, temporibus
                                                delectus dolores numquam ratione vero itaque ducimus ipsam
                                                adipisci beatae blanditiis corrupti veritatis?Lorem ipsum dolor
                                                sit amet consectetur ea praesentium sapiente excepturi
                                                consequuntur hic aspernatur, temporibus delectus dolores numquam
                                                ratione adipisicing elit. </p>
                                        </div>
                                    </div>
                                </div> */}


                                {/* <section> */}



                                <h3>Stand up with Akoda for  Medical Kits Distribution event</h3>
                                <p>Distribution Medical Kits to needy orphans & senior citizens and mentally disabled people at the Nalgonda District Telangana.
The Medical Kits donation program was organized at various places with the donation of Mr. Kranthi Kumar. Through Swarna Foundation association with Akoda foundation under the “Healthy lifes” Initiative. Food was distributed to the needy orphans & senior citizens at the Nalgonda District Telangana.
Medical Kits Initiative was sponsored by Mr.Kranthi Kumar on the remembrance of death anniversary of Swarna . 
Responsibilities in Nalgonda were carried out by Shri Kranthi Kumar.
A total of  hundred people were benefited through today’s initiative.

Thanks to Akoda Team.

</p>

                               

                                

                                <div className="gap-mb-20"></div>

                                {/* <h3>Event Speakers</h3> */}

                                {/* <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single-speakers">
                                            <img src={speaker1} alt="Image" />

                                            <div className="speaker-title">
                                                <h4>Louis Gale</h4>
                                                <span>Engineer</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="single-speakers">
                                            <img src={speaker2} alt="Image" />

                                            <div className="speaker-title">
                                                <h4>Audrey Clarke</h4>
                                                <span>CEO</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="single-speakers">
                                            <img src={speaker3} alt="Image" />

                                            <div className="speaker-title">
                                                <h4>Vera Macias</h4>
                                                <span>Founder</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>



                <div className="col-lg-4">
                    <div className="widget-sidebar ml-15">
                        <div className="sidebar-widget mt-0" >
                            <form className="choose-us-from-bg">


                                <div className="others-options" style={{ padding: '2px', paddingBottom: '37px' }}>
                                    <ul>
                                        <li>
                                            <Link to="/donate-now" className="default-btn border-radius-btn"
                                                style={{ padding: '15px', fontSize: '20px', marginTop: '-25px', borderRadius: '0px' }}>
                                                Donate Now
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <a href="https://www.facebook.com/profile.php?id=100079026490521" target="_blank">
                                    <div className="others-options"
                                        style={{ border: '2px solid #344E86', padding: '18px 15px 15px 15px', borderRadius: '10px', backgroundColor: '#344E86', paddingLeft: '43px' }}>
                                        <img src={facebook} height="25px" width="25px" alt="" />
                                        <span
                                            style={{ fontWeight: 'bold', color: 'rgb(255, 248, 248)', paddingLeft: '10px' }}>Spread
                                            the word (780 shares)</span>
                                    </div>
                                </a>


                            </form>
                        </div>
                        <div className="text-center">
                        <img src={savePlants}  alt="" style={{height:'300px',textAlign:'center'}}/>
                        </div>
                       
                        {/* <div style={{ paddingLeft: '30px' }}>
                            <span style={{ fontSize: '40px' }}>₹ 4,03,93,110</span><br />
                            <span>raised of <b>₹ 5,00,00,000 </b>goal</span>
                            <div className="progress-bar__container">
                                <div className="progress-bar">
                                    <span className="progress-bar__text">Uploaded Successfully!</span>
                                </div>
                            </div>
                            <pre
                                style={{ marginTop: '11px' }}> <span style={{ fontSize: '20px' }}> 20789 </span>supporters <span style={{ fontSize: '20px' }}>       25</span>days left</pre>
                        </div> */}

                        <div className="sidebar-widget">
                            <h3>Event location</h3>

                            <div className="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.390700423798!2d78.3803008153709!3d17.441004405846062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e1bef1173f%3A0xedf7c46288fdf31a!2sKTC%20Illumination%2C%20Vittal%20Rao%20Nagar%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1663236626782!5m2!1sen!2sin"></iframe>
                                 </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- End Even     t Details Page Area --> */}

</div>
  )
}

export default MedicalKitEvent