import React from "react";

import img1 from "./img/home-img1.jpg";
import fundraising from "./assets/images/page-bg/fundraising.jpg"
import { Link } from "react-router-dom";

import OurCauses from "./OurCauses";
import { Helmet } from "react-helmet";

const Fundraisers = () => {
  {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div>
      <Helmet>
        <title>
        fundraisers |Akoda SK foundation | Health| Medical Treatment | medical fundraising | medical fundraising in Hyderabad | Best Crowdfunding Platform in telangana | Donate  |Fundraising Campaign |  Hyderabad | India 
        </title>
        <meta
          name="description"
          content=" Emergency medical care comes without notice. Sometimes health insurance and savings aren't enough to cover expensive bills. Crowdfunding receives small donations from many people to help you achieve your goals."
        />
        <meta
          name="keywords"
          content="Akoda sk foundation ,Akodafundraisers ,medical fundraising in Hyderabad, Oldage Homes fundraising in Hyderabad , best fundraising platform for nonprofits,  Education Child fundraising, Orphan Service in Hyderabad telangana, Crowdfunding ,Fundraising , Best Crowdfunding Platform in hyderabad, best fundraising platform in hyderabad telangana ,Fundraising Campaign in telangana,social service platform in Hyderabad telangana, Hyderabad, India  "
        />
      </Helmet>
  

      {/* <!-- Search Modal --> */}
      <div className="page-title-area bg-2"  style={{  backgroundImage: `url(${fundraising})`}}>
        <div className="container">
          <div className="page-title-content">
            <h2> Akoda Fundraisers </h2>

            <ul>
              <li>
                <Link to="/donate-now" style={{ color: "#000" }}>
                  Save a child every month
                </Link>
              </li>
            </ul>
            <Link
              to="/donate-now"
              className="default-btn "
              style={{ color: "rgb(69 159 109", marginTop: "45px" }}
            >
              MONTHLY DONATE NOW
            </Link>
          </div>
        </div>
      </div>
      {/* <!-- Start Banner Area --> */}

      <section className="blog-post-area " style={{ marginTop: "30px" }}>
        <div className="container">
          <div className="row ">
            <div
              className="col col-lg-2 col-md-2 col-sm-2 col-xs-2"
              style={{ padding: "0px" }}
            >
              <div className="text-right">
                <img
                  alt=""
                  src={img1}
                  className="adds-size"
                  style={{
                    width: "151px",
                    height: "151px",
                    borderRadius: "10px 0px 0px 10px",
                  }}
                />
              </div>
            </div>
            <div
              className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 height-bb"
              style={{ background: "#f5f4f4" }}
            >
              <div className="section-title section-title-left height-rr">
                <h2 style={{ paddingTop: "10px" }}>
                  Let’s Join Hands and Raise Funds
                </h2>
                <p>
                  Akoda understands the importance of raising funds to help
                  those who are in dire need of it and believes in your
                  generosity.
                </p>
              </div>
            </div>

            <div
              className="col col-lg-3 col-md-3 col-sm-3 col-xs-3 height-bbb"
              style={{
                background: "#f5f4f4",
                padding: "55px",
                borderRadius: "0px 10px 10px 0px",
              }}
            >
              <div className="text-left">
                <Link
                  to="/donate-now"
                  className="default-btn border-btn text-center height-rrr"
                >
                  {" "}
                  Donate Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurCauses />

    </div>
  );
};

export default Fundraisers;
