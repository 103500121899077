import React from 'react'
// import icons from './img/share-icon.png'
// import fb from './images/fb.png'
// import twitter from './images/twitter.png'
// import google from './images/google.png'
// import youtube from './images/youtube.png'
// import insta from './images/insta.png'
// import pintrest from './images/pintrest.png'

const SocialMedia = () => {

  return (
    <div>
      <div className="share_content">
        <div className="share_button">
          <i className="fa-solid fa-share-nodes" style={{ fontSize: '25px' }}></i>
        </div>
        <div className="share_icons">
          <a href="https://www.facebook.com/akodafundraisers/?ref=pages_you_manage" className="icon_button"><i className="fab fa-facebook-f"></i></a>
          <a href="https://twitter.com/AkodaFundraise1" className="icon_button"><i className="fab fa-twitter"></i></a>
          <a href="https://youtube.com/channel/UCb2lNV3dVv6vBin7wDQLrIQ" className="icon_button"><i className="fab fa-youtube"></i></a>
          <a href="https://www.instagram.com/akodafundraisers/" className="icon_button"><i className="fa-brands fa-instagram"></i></a>
          <a href="https://pin.it/2DFcMQy" className="icon_button"><i className="fa-brands fa-pinterest"></i></a>
          <a href="https://www.linkedin.com/in/akoda-fundraisers-867181234/" className="icon_button"><i className="fa-brands fa-linkedin-in"></i></a>
        
          {/* <a href="" className="icon_button"><i className="fa-brands fa-google-plus-g"></i></a> */}

          {/* <div class="more_share">
                    <i class="fas fa-arrow-circle-down"></i>
                </div> */}

          {/* <div class="more_content">
                    <a href="" class="icon_button"><i class="fab fa-facebook-f"></i></a>
                    <a href="" class="icon_button"><i class="fab fa-twitter"></i></a>
                    <a href="" class="icon_button"><i class="fab fa-youtube"></i></a>
                    <a href="" class="icon_button"><i class="fab fa-facebook-messenger"></i></a>

                    <a href="" class="icon_button"><i class="fab fa-facebook-f"></i></a>
                    <a href="" class="icon_button"><i class="fab fa-twitter"></i></a>
                    <a href="" class="icon_button"><i class="fab fa-youtube"></i></a>
                    <a href="" class="icon_button"><i class="fab fa-facebook-messenger"></i></a>

                    <a href="" class="icon_button"><i class="fab fa-facebook-f"></i></a>
                    <a href="" class="icon_button"><i class="fab fa-twitter"></i></a>
                    <a href="" class="icon_button"><i class="fab fa-youtube"></i></a>
                    <a href="" class="icon_button"><i class="fab fa-facebook-messenger"></i></a>
                </div> */}
        </div>
      </div>



    </div >
  )
}

export default SocialMedia