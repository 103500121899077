import React, { useRef, useState } from 'react'
import axios from 'axios'
import background from './img/flip.png'
import ei1 from './img/education-icon/1.png'
import ei2 from './img/education-icon/2.png'
// import ei4 from './img/education-icon/4.png'
import ei5 from './img/education-icon/5.png'
import ei8 from './img/education-icon/8.png'
import ei9 from './img/education-icon/9.png'
import Slider from 'react-slick'
import emailjs from '@emailjs/browser'
import Volunteer from './Volunteer';
import volun from './assets/images/volunteer.png'
import { Link } from 'react-router-dom'

const BeVolunteer = () => {
    { window.scrollTo({ top: 0, behavior: 'smooth' }); };
    const [userFullName, setUserFullName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userDistrict, setUserDistrict] = useState("");
    // const [userSubject, setUserSubject] = useState("");
    const [usermessage, setUsermessage] = useState("");
    const onChangeUserFullName = (event) => {
        setUserFullName(event.target.value)
    }
    const onChangeUserEmail = (event) => {
        setUserEmail(event.target.value)
    }
    const onChangeUserPhone = (event) => {
        setUserPhone(event.target.value)
    }
    const onChangeUserDistrict = (event) => {
        setUserDistrict(event.target.value)
    }
    // const onChangeUserSubject=(event)=>{
    //    setUserSubject(event.target.value)
    // }
    const onChangeUsermessage = (event) => {
        setUsermessage(event.target.value)
    }
    const onCreatePost = (event) => {
        event.preventDefault()
        const postData = {
            userFullName,
            userEmail,
            userPhone,
            userDistrict,
            //    userSubject,
            usermessage,
        }
        axios.post('http://localhost:4000/app/signup', postData,)
            .then((response) => {
                console.log(response);
            })
    };
    const form = useRef();
    const sendEmail = (event) => {
        event.preventDefault();
        emailjs.sendForm('service_ksp4khq', 'template_1u3263l', form.current, 'QDT9q3KiW_w51CvX3')
            .then((result) => {
                console.log(result.text);
                toastFunction();
            }, (error) => {
                console.log(error.text);
            });
    };
    function toastFunction() {
        var x = document.getElementById("toast");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 1000);
        document.getElementById("myForm").reset();
    }
    var eduFlip = {
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        infinite: false,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2500,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2500,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2500,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2500,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2500,
                }
            }
        ]
    };
    var causesSliderone = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        infinite: true,
        arrows: false,
        dots: false,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                }
            }
        ]
    };
    return (
        <div>
            {/* <Nav /> */}
            <section className="donation-area">
                <div className="fluid-container">
                    <div className="donation-bg-volanteer">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-8"><div className="col col-md-6 col-sm-6 col-xs-6 d-table ">
                                    <div className="d-table-cell">
                                        <div className="container">
                                            <div className="banner-content">
                                                {/* <span className="top-title">WHAT'S PROCESS</span> */}
                                                <h3>“Never doubt that a small group of thoughtful, committed citizens can change the world;
                                                    indeed, it's the only thing that ever has.”<span style={{ color: '#0286CD', fontSize: '28px' }}> Be A volunteer Today
                                                    </span> </h3>
                                                <p>AkoDa’s 0% Platform fees ensures maximum funds for you</p>

                                                <div className="banner-btn">

                                                    <Link to="/contact-us" className="default-btn border-radius-btn">Contact Us</Link>
                                                    {/* <Link to="/donate-now" className="default-btn border-radius-btn">
                                                        Start a Fundraiser
                                                    </Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></div>
                                <div className="col-lg-4">
                                    <div className="form " style={{ backgroundColor: 'rgb(189 220 237)', boxShadow: '5px 1px 16px #0286cd', height: '543px' }}>
                                        <h5 className="text-center">
                                            {/* <br /> */}
                                            <i style={{ color: '#fff', textShadow: '2px 1px #85133ee3', fontSize: '25px !important' }}>  Be A Volunteer. </i>
                                        </h5>
                                        <div className="input-container ic1" >
                                            <form ref={form} onSubmit={(event) => { onCreatePost(event); sendEmail(event); }} id="myForm" style={{ padding: '5px', color: 'black' }}>
                                                <label>Name</label>
                                                <input type="text" name="user_name" onChange={onChangeUserFullName} value={userFullName} required />
                                                <label>Email</label>
                                                <input type="email" name="user_email" onChange={onChangeUserEmail} value={userEmail} required />
                                                <label>Phone Number</label>
                                                <input type="number" name="user_phone" onChange={onChangeUserPhone} value={userPhone} required />
                                                <label>District</label>
                                                <input type="text" name="user_district" onChange={onChangeUserDistrict} value={userDistrict} required />
                                                <label>Address</label>
                                                <textarea type="text" name="message" onChange={onChangeUsermessage} value={usermessage} required style={{ width: '100%', backgroundColor: '#fff' }} />
                                                {/* <label>Message</label>
                                                <textarea name="message" /> */}
                                                <input type="submit" value="Submit" onClick={() => 'toastFunction'} style={{ backgroundColor: '#0286CD', }} />
                                                <div id="toast">
                                                    <div className="checkicon"> <i className="fas fa-check-square"></i> </div>
                                                    SUCCESS: We have received your registration. Thanks!</div>
                                            </form>
                                            {/*<form ref={form} onSubmit={sendEmail}>
                                            <input className="input" name="fname" type="text" placeholder=" " />
                                            <div className="cut"></div>
                                            <label for="firstname" className="placeholder">First Name</label>   
                                            <input className="input" name="lname" type="text" placeholder=" " />
                                            <div className="cut"></div>
                                            <label for="lastname" className="placeholder">Last Name</label>
                                            <input className="input" name="number" type="number" placeholder=" " />
                                            <div className="cut"></div>
                                            <label for="firstname" className="placeholder">Phone Number</label>
                                            <input className="input" type="mail" name="userEmail" placeholder=" " required />
                                            <div className="cut"></div>
                                            <label for="email" className="placeholder">Email</label>
                                            <input className="input" name="country" type="country" placeholder=" " />
                                            <div className="cut cut-short"></div>
                                            <label for="country" className="placeholder">Country </label>
                                            <input type="submit" className="submit" value="Send" />
                                        </form> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-area about-style-three about-us-mobile ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className=" " style={{ marginTop: '160px' }} >
                                <img src={volun} alt="Image" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content page-style ml-15">
                                <div className="text-center">
                                    <span className="top-title" >ABOUT Akoda's volunteerism</span>
                                </div>
                                <h2 className="text-center">We are Leud charity foundation</h2>
                                <p>Volunteering can help you learn new skills, gain experience and sometimes even qualifications. Take on a challenge. Through volunteering you can challenge yourself to try something different, achieve personal goals, practice using your skills and discover hidden talents.</p>
                                <ul>
                                    {/* <li>
                                        <i className="ri-check-line"></i>
                                        20+ years of experience
                                    </li> */}
                                    <li>
                                        <i className="ri-check-line"></i>
                                        Let's use donation properly
                                    </li>
                                    <li>
                                        <i className="ri-check-line"></i>
                                        Our actions are proof of our Believe
                                    </li>
                                    <li>
                                        <i className="ri-check-line"></i>
                                        We do charity events every month
                                    </li>
                                </ul>
                                <div className="banner-btn">

                                    <Link to="/donate-now" className="default-btn border-radius-btn">
                                        Donate Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section >
                <div className="row edu-flip box-container">
                    <Slider {...eduFlip}>
                        <div className="col-lg-3 box-item">
                            <div className="flip-box">
                                <div className="flip-box-front text-center" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="inner color-white">
                                        <img src={ei1} alt="Image" width="80" />
                                        <h3 className="flip-box-header">Medical Fundraising</h3>
                                    </div>
                                </div>
                                <div className="flip-box-back text-center" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="inner color-white">
                                        <h3 className="flip-box-header">Medical Fundraising</h3>
                                        <p>Medical emergencies come without a warning.Health insurance and savings are not enough to cover the expensive bill.Crowdfunding helps you financially.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 box-item">
                            <div className="flip-box">
                                <div className="flip-box-front text-center" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="inner color-white">
                                        <img src={ei2} alt="Image" width="80" />
                                        <h3 className="flip-box-header">Education Crowdfunding</h3>
                                    </div>
                                </div>
                                <div className="flip-box-back text-center" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="inner color-white">
                                        <h3 className="flip-box-header">Education Crowdfunding</h3>
                                        <p>Akoda India’s Most Promising Online Crowdfunding Platform to Raise Funds for Education</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 box-item">
                            <div className="flip-box">
                                <div className="flip-box-front text-center" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="inner color-white">
                                        <img src={ei3} alt="Image" width="80" />
                                        <h3 className="flip-box-header">Animal Crowdfunding</h3>
                                    </div>
                                </div>

                                <div className="flip-box-back text-center" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="inner color-white">
                                        <h3 className="flip-box-header">Animal Crowdfunding</h3>
                                        <p>Animal Conservation Organisations receive donations online through Akoda’s Online Crowdfunding Platform for Animal Fundraising.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-3 box-item">
                            <div className="flip-box">
                                <div className="flip-box-front text-center" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="inner color-white">
                                        <img src={ei4} alt="Image" width="80" />
                                        <h3 className="flip-box-header">Child Welfare Support</h3>
                                    </div>
                                </div>
                                <div className="flip-box-back text-center" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="inner color-white">
                                        <h3 className="flip-box-header">Child Welfare Support</h3>
                                        <p>Akoda's online crowdfunding platform to raise funds for child welfare</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-3 box-item">
                            <div className="flip-box">
                                <div className="flip-box-front text-center" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="inner color-white">
                                        <img src={ei5} alt="Image" width="80" />
                                        <h3 className="flip-box-header">Extracurricular Programs</h3>
                                    </div>
                                </div>
                                <div className="flip-box-back text-center" style={{ backgroundImage: `url(${background})` }}>
                                    <div className="inner color-white">
                                        <h3 className="flip-box-header">Extracurricular Programs</h3>
                                        <p>Don't miss out on this opportunity to raise more money for excellence through Crowdfinancing!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="our-mission-area pt-100">
                <div className="container">
                    <div className="section-title section-white-title section-title-three">
                        <span className="top-title"> SERVICES </span>
                        <h2 style={{ color: '#000' }}>Akoda stand out among other online crowdfunding platforms for raising funds</h2>
                    </div>
                    <div className="row justify-content-center">
                        {/* <div className="col-lg-6 col-md-6 service-serv">
                            <div className="single-mission style-three">
                                <img src={ei6} alt="Image" width="70" />
                                <span>01</span>
                                <h3>Payment</h3>
                                <p>No need to wait to receive funds. Akoda ensures that the collected funds can be withdrawn at any time during the fundraising process.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 service-serv">
                            <div className="single-mission style-three">
                                <img src={ei7} alt="Image" width="70" />
                                <span>02</span>
                                <h3>Providing international payment support</h3>
                                <p>We accept donations in multiple currencies from around the world, so you can reach out to like-minded students and peers abroad.</p>
                            </div>
                        </div> */}
                        <div className="col-lg-6 col-md-6 service-serv">
                            <div className="single-mission style-three">
                                <img src={ei8} alt="Image" width="70" />
                                <span>01</span>
                                <h3>24*7 Support</h3>
                                <p>With 24x7 on-call assistance, WhatsApp, Email, SMS, and a personal chat interface, there is always someone around to assist you.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 service-serv">
                            <div className="single-mission style-three">
                                <img src={ei9} alt="Image" width="70" />
                                <span>02</span>
                                <h3>Fundraising expert dedicated to raising funds</h3>
                                <p>A dedicated fundraiser expert is assigned to you; he or she will guide you through your fundraising journey and answer all your fundraising questions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Volunteer />
        </div>
    )
}

export default BeVolunteer