import React from 'react'
// import Partners from './Partners'
import about1 from './img/about1.jpg';
import aboutUs from './assets/images/page-bg/aboutUs.jpg'
import { Helmet } from 'react-helmet';
// import team1 from './assets/images/volunteers/team-1.jpg'
// import team2 from './assets/images/volunteers/team-2.jpg'
// import team3 from './assets/images/volunteers/team-3.jpg'
// import Volunteer from './Volunteer';
import { Link } from 'react-router-dom'
import Testimonial from './Testimonial'
import OurTeam from './OurTeam';

const AboutUs = () => {
	{ window.scrollTo({ top: 0, behavior: 'smooth' }); };
	return (
		<div>
			<Helmet>
				<title>About Us |Akoda SK foundation| Akoda fundraisers | about-us | Education |Old age Homes |Pandemic Food Supply |Education Child| Orphan Service |Crowdfunding | Fundraising |Best Crowdfunding Platform | Donate  |Fundraising Campaign | Hyderabad | India </title>
				<meta name="description" content=" Akoda works towards educating children through its book donation drives. Every year the organization collects approximately 20,000 books" />
				<meta name="keywords" content="Akoda sk foundation ,Akodafundraisers ,ngo, Old age Homes,  Education Child in hyderabad, Orphan Service in Hyderabad telangana, Crowdfunding ,Fundraising, Best Crowdfunding Platform, best fundraising platform in hyderabad ,best fundraising platform in telangana,Donate, Rescue,Fundraising Campaign,best fundraising platform Hyderabad telangana,social service platform in Hyderabad telangana, Hyderabad, India " />

			</Helmet>
			{/* <Nav /> */}
			{/* <!-- Search Modal --> */}
			<div className="modal fade search-modal-area" id="exampleModalsrc">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<button type="button" data-bs-dismiss="modal" className="closer-btn">
							<i className="ri-close-line"></i>
						</button>
						<div className="modal-body">
							<form className="search-box">
								<div className="search-input">
									<input type="text" name="Search" placeholder="Search for..." className="form-control" />

									<button type="submit" className="search-btn">
										<i className="ri-search-line"></i>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Search Modal --> */}
			{/* <!-- Start Page Title Area --> */}
			<div className="page-title-area  bg-1" style={{ backgroundImage: `url(${aboutUs})` }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
						</div>
						<div className="col-lg-4">
							<div className="page-title-content">
								{/* <h2>About Us</h2> */}
								<ul>
									{/* <li >
										<Link to="/" style={{color:'#fff', fontWeight:'600!'}}>
											Home
										</Link>
									</li>
									<li className="active"> About Us </li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- End Page Title Area --> */}
			{/* <!-- Start About Area --> */}
			<section className="about-area about-style-three about-us-mobile ptb-100 pd-top">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-img text-center">
								<img src={about1} alt="Image" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-content page-style ml-15">
								<div className="text-center">
									<span className="top-title" >ABOUT US</span>
								</div>
								<h2 className="text-center">A brief introduction about us.</h2>
								<p>We are an NGO in Telangana with a crowdfunding online platform to support educationand healthcare for children from the underprivileged segment of the society. Most of them cannot afford to pay for basic necessities, especially healthcare and education. Fundraising through crowdfunding is an ideal way to make it happen. </p>
								<h2 className="text-center">Our Achievement</h2>
								<p>We have been contributory in bringing a positive change in thousands of lives with the help of generous donors. Our constant and committed efforts have transformed us into the best online fundraising platform in Hyderabad. We've raised Rs. 100+ crores to pay for various expenses like medicines, diagnostics, tuition fees, study material etc.</p>
								<h2 className="text-center">Our Motto</h2>
								<p>Our motto is to smile and let smile. This cannot be achieved without your helping hand. Help us spread joy. Let’s be useful together.</p>
								<ul>
									<li>
										<i className="ri-check-line"></i>
										10+ years of experience
									</li>
									<li>
										<i className="ri-check-line"></i>
										Let's use donation properly
									</li>
									<li>
										<i className="ri-check-line"></i>
										Our actions are proof of our Believe
									</li>
									<li>
										<i className="ri-check-line"></i>
										We do charity events every month
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- End About Area --> */}
			{/* FOunder words */}
			{/* <section className="about-area about-style-three about-us-mobile ptb-100">
				<div className="container">
					<div className="row align-items-center obu">
						<div className="col-lg-6">
							<div className="about-content page-style ml-15">
								<div className="text-center">
									<span className="top-title" >Founder Words</span>
								</div>
								<p>We are an NGO in Telangana with a crowdfunding online platform to support educationand healthcare for children from the underprivileged segment of the society. Most of them cannot afford to pay for basic necessities, especially healthcare and education. Fundraising through crowdfunding is an ideal way to make it happen. </p>
								<p>We have been contributory in bringing a positive change in thousands of lives with the help of generous donors. Our constant and committed efforts have transformed us into the best online fundraising platform in Hyderabad. We've raised Rs. 100+ crores to pay for various expenses like medicines, diagnostics, tuition fees, study material etc.</p>
								<p>Our motto is to smile and let smile. This cannot be achieved without your helping hand. Help us spread joy. Let’s be useful together.</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img mr-15 text-center">
								<img src={about1} alt="Image" />
							</div>
						</div>
					</div>
				</div>
			</section> */}
			<OurTeam />
			{/* <Volunteer /> */}
			{/* <!-- Start Testimonial Area --> */}
			<Testimonial />
			{/* <!-- End Testimonial Area --> */}
		</div>
	)
}

export default AboutUs