import React from 'react'

import event4 from '../Component/EventImages/4.png'
import siren from './assets/images/events/siren.png'
import feedingPoor from '../Component/EventImages/feedingPoor.jpg'
import facebook from './assets/images/events/facebook.png'
import { Link } from 'react-router-dom'
import akoda1 from './assets/images/gallery/akoda13.jpeg'


const AkodaEventOne = () => {

    { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>
         

            <div className="section-title section-title-left" >
                {/* <img src={siren} style={{ width: '30px', height: '30px' }} alt="Image" /> */}
                {/* <span className="top-title"> Akoda Enent One  </span><br /> */}
                <span className="container" style={{ color: 'rgb(17, 15, 15)', fontSize: '32px' }}>
                🌹 In Loving Memory of Swarna An Annadanam in Celebration of Her Life 🌹</span>
            </div>


        
            
            <section className="evet-details-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="event-details-content ">
                                <div className="event-details-img">
                                    <img src={akoda1} alt="Image" />
                                    <span className="date">02 <br /> FEB</span>
                                </div>

                                <div className="event-top-content">
                                    <div className="event-content" style={{textAlign:'justify'}}>
                                       


                                        <h3>🕯️ Swarna Memorial Annadanam - Sharing Love Through Sustenance 🕯️</h3>
                                        <p>In honoring the beautiful soul that was Swarna, we offered annadanam, providing nourishment to those in need. This act reflects the love and kindness that defined her spirit, and by participating, we extend her legacy of generosity to those less fortunate.</p>

                                        <p>As we gather to remember Swarna on her birthday, let us channel the love she showered upon us into a gesture that can bring solace and sustenance to others. Your participation will transform this day into a tribute filled with compassion, echoing the everlasting impact she had on our lives.
                                            Thank you for joining me in remembering and celebrating the beautiful soul that was </p>
                                        <p>Monetary Contributions: Your generous donations will help in preparing and distributing a warm and comforting meal in memory of Swarna.
                                            Volunteer Assistance: Join me in the spirit of service by volunteering your time to help organize and facilitate the annadanam.</p>

                                        

                                        <div className="gap-mb-20"></div>

                                      
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-4">
                            <div className="widget-sidebar ml-15">
                                <div className="sidebar-widget mt-0" >
                                    <form className="choose-us-from-bg">


                                        <div className="others-options" style={{ padding: '2px', paddingBottom: '37px' }}>
                                            <ul>
                                                <li>
                                                    <Link to="/donate-now" className="default-btn border-radius-btn"
                                                        style={{ padding: '15px', fontSize: '20px', marginTop: '-25px', borderRadius: '0px' }}>
                                                        Donate Now
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <a href="https://www.facebook.com/profile.php?id=100079026490521" target="_blank">
                                            <div className="others-options"
                                                style={{ border: '2px solid #344E86', padding: '18px 15px 15px 15px', borderRadius: '10px', backgroundColor: '#344E86', paddingLeft: '43px' }}>
                                                <img src={facebook} height="25px" width="25px" alt="" />
                                                <span
                                                    style={{ fontWeight: 'bold', color: 'rgb(255, 248, 248)', paddingLeft: '10px' }}>Spread The Word</span>
                                            </div>
                                        </a>


                                    </form>
                                </div>


                                            <div className="text-center">
                                        <img src={feedingPoor} alt="" style={{height:'300px',textAlign:'center'}}/>
                                        </div>
                                <div className="sidebar-widget">
                                    <h3>Event location</h3>

                                    <div className="map">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.390700423798!2d78.3803008153709!3d17.441004405846062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e1bef1173f%3A0xedf7c46288fdf31a!2sKTC%20Illumination%2C%20Vittal%20Rao%20Nagar%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1663236626782!5m2!1sen!2sin"></iframe>
                                         </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

          

      
        </div>
  )
}

export default AkodaEventOne