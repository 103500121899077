import React from 'react'

import { Link } from 'react-router-dom'

const TermsAndConditions = () => {
    {   window.scrollTo({top: 0, behavior: 'smooth'});};
    return (
        <div>
       
            <div className="container">
                <h2 className="text-center">Terms and Conditions</h2>
                <p>We do not provide any financial return in any form whatsoever, including but not limited to financial securities (debt or equity), interest, dividend, profit share, rewards in cash, to individuals who contribute on Akoda.org.
                    Any contribution on Akoda.org, by an individual, should not be construed as an investment in any form whatsoever.</p>
                <ol start={1}>
                    <li>This document is an electronic record in terms of Information Technology Act, 2000 and rules thereunder as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.    </li>
                    <li>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, as amended, supplemented or replaced from time to time, that require publishing the Privacy Policy and Terms of Use for access or usage of the website, www.Akoda.org</li>
                    <li>The domain name www.Akoda.org (hereinafter referred to as "Website")is licensed for use by Akoda Online Ventures Private Limited, an Indian Company, duly incorporated under the Indian Companies Act, 1956, with its registered office in Hyderabad, India. Akoda Online Ventures Inc, duly incorporated under General Corporation Law of Delaware is wholly owned subsidiary of Akoda Online Ventures Private Limited (hereinafter both collectively referred to as "Akoda").</li>
                    <li>Your use of the Website and Services and tools are governed by the following terms and conditions as mentioned herein as applicable to the Website including the applicable policies, which are incorporated herein by way of reference. If you transact on the Website, you shall be subject to the policies that are applicable to the Website for such transaction. By mere use of the Website, you shall be contracting with Akoda Online Ventures Private Limited and Akoda Online Ventures Inc. and these terms and conditions including the policies constitute your binding obligations, with Akoda.</li>
                    <li>For the purpose of these Terms of Use, wherever the context so requires 'you' or 'User' shall mean any natural or legal person which shall include both Donors / Contributors (as defined hereinbelow) and Campaigner (as defined herein below) as and where applicable and to be understood so) who has agreed to become a member/user of the Website by providing Registration Data while registering on the Website or signing in through an Associated Partner (via the API), as a Registered User using the computer systems (all such Donors / contributors/ and Campaigner shall hereinafter be collectively referred to as “User”). Akoda allows all person(s) to surf / explore the Website without registering on the Website. However, Akoda does not allow any contributors/donors to make financial contributions or contributions in kind to any of the campaigner with prior registration / signing in. The term "we", "us", "our" shall mean Akoda and the term “you” and “your” referred to herein shall deem to mean the User</li>
                    <li>When you use any of the Services provided by us through the Website, including but not limited to, (e.g. Reviews, Comments, Feedback, etc), you will be subject to the rules, guidelines, policies, terms, and conditions applicable to such service, and they shall be deemed to be incorporated into these Terms of Use and shall be considered as part and parcel of these Terms of Use. We reserve the right, at our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to you. It is the responsibility of the User to review these Terms of Use periodically for updates / changes. Your continued use of the Website following the posting of changes will mean that You accept and agree to the revisions.</li>


                </ol>
                <p>ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. BY IMPLIEDLY OR EXPRESSLY ACCEPTING THESE TERMS OF USE, YOU ALSO ACCEPT AND AGREE TO BE BOUND BY THE AKODA PRIVACY POLICY.</p>

                <ol start={1}>
                    <li>Services of Akoda  </li>
                    <ul>
                        <li>Akoda is a marketing service provider and the Website merely provides platform services which can be utilized by Users (being Donors / Contributors and Campaigner) and persons browsing / visiting the Website to reach a larger base of people, organizations and causes. Akoda is only providing a platform for communication and it is agreed that the transactions whether gratuitous or otherwise shall always be bipartite between the Donor / Contributor and the Campaigner.</li>
                        <li>The Website is a platform that Donors utilize to meet and interact with various Campaigners and the representatives of several non-profit organizations in India. Based on the representations of the campaigns started by such person(s) and/or a cause supported by such Campaigners and Non ProfitOrganisations in India, Donors enter into transactions for making contributions or sharing information. All information provided about the campaigns, is that which is provided by the respective Campaigner initiating the Campaign and the non- profit organizations in India for which/on behalf of who the campaigns have been started to raise funds.</li>
                        <li>The User acknowledges that Akoda is only a marketing service provider and does not verify the bona fides and genuineness of campaigns, and Akoda cannot and does not verify specific data provided by Campaigner. Therefore, Akoda shall not in any manner be responsible or held accountable for any transaction between the Users.</li>
                        <li>Akoda has limited control over all campaigns and information listed and / or advertised on the Website and/ or Campaign generated information. It is merely an intermediary and does not interfere in the transaction between Donors / Contributors and Campaigner.</li>
                        <li> It is hereby clarified that at no time shall Akoda hold any right, title or interest over the funds or rewards (except to the extent as stated herein) or have any obligations or liabilities to provide the same to the Donor / contributor. Akoda is not responsible for unsatisfactory or delayed performance of the Campaign by the Campaigner.</li>
                    </ul>

                    <li>Eligibility of the User (as defined here in below)</li>
                    <ul>
                        <li>Use of the Website is available only to persons who can form legally binding contracts under the Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents, etc., are not eligible to use the Website. If you are a minor i.e. under the age of 18 years, you shall not register as a member on Akoda and shall not transact directly (either contribute or Campaign to receive donations / contributions) or use the Website. As a minor if you wish to use or transact on the Website, such use or transaction may be made by your legal guardian, parents or through an organization / NGO competent to contract on the Website. Akoda reserves the right to terminate your membership and / or refuse to provide you with access to the Website if it is brought to Akoda's notice or if it is discovered that you are under the age of 18 years.</li>
                        <li>Organizational Campaigner / Legal Person's Registration: If you are registering as an Organization / Entity or generally as a Legal Person, not being a natural person, you represent that you are duly authorized by the Organization / Entity Management to accept these Terms of Use and you have the authority to bind the Organization / Entity to these Terms of Use.</li>
                    </ul>

                    <li>Campaigner</li>
                    <ul>
                        <li>A Non Profit Organization in India, which is registered on Akoda and/or a person who is a supporter or intends to further the cause of such Non Profit Organizations in India or an Individual with an Indian bank account or a private limited company with a bank account in India, is permitted to present/host a campaigns on the Website of Akoda in accordance with the Policies which are incorporated by way of reference in this Terms of Use and any agreement entered into by Akodaand any such person (a Non Profit Organization, an Individual or a company in India registered with or is a member of Akoda and/or any person hosting a Campaign on the Website shall hereinafter collectively be referred to as a “Campaigner”).</li>
                        <li>A Campaigner is bound and legally liable to provide accurate, true and correct information in respect of the details of the cause for which the Campaign is hosted and funds are being raised on our Website.</li>

                        <li>A Campaigner shall ensure that the description of the Campaign is not misleading and describes the need and use of the funds to be raised. Akoda reserves the right to delete your Campaign if it is brought to the notice of Akoda that the information provided by you is false, does not depict the true nature of the Campaign or is misleading in any manner.</li>
                        <li>You shall ensure that the documents and/or information submitted/ disclosed by you to Akoda in support of the Campaign are at all times true, correct, up to date, and does not relate to any other person besides you or the beneficiary.</li>
                        <li>A Campaigner shall ensure that the information and the fundraiser do not infringe upon the intellectual property, trade secret or other proprietary rights or rights of publicity or privacy rights of third parties. Campaign listings may only include text descriptions, graphics and pictures that describe your Campaign and the need and the use of the funds raised. If any rewards or promotions are being offered to the Donors / Contributors, you acknowledge the same products shall be kept in stock by you and if services personal or otherwise shall be fulfilled by you.</li>
                        <li>It shall at all times be the responsibility of the Campaigner to furnish the Donor with an accurate progress report and any other information that may be required by the Donor to track the utilization of the funds donated by the Donor to the Campaigner via Akoda.</li>
                        <li>You further agree that by listing your Campaign on the Website, you shall at all times abide by various laws applicable to You, as amended from time to time, including but not limited to the Income Tax Act, 1961, the Foreign Contributions Regulation Act, 2010, the Information Technology Act, 2000, as well as all rules and regulations made under the respective laws. You shall ensure that the you and/ or the beneficiary are not prohibited from raising donations from foreign sources under Section 3 of the Foreign Contribution (Regulation) Act of 2010, or any other applicable law for the time being in force; and it shall be your responsibility to ensure that all the licenses/ permissions procured by you/the beneficiary (as the case may be) in relation to the Campaign are valid and subsisting throughout the duration of the Campaign and while the donated funds are being utilized by the beneficiary.</li>
                        <li>If you or the beneficiary on behalf of whom you are raising funds, have ever been, or during the course of the Campaign are, investigated for and/ or charged with any crime of or relating to theft, fraud, embezzlement, moral turpitude, money laundering, forgery, misrepresentation or terrorist activities or blacklisted by any governmental authority in any country, including under any sanctions list maintained by the United States Office of Foreign Asset Control, you should report this to Akoda promptly. You acknowledge that in such cases, Akoda may, without any liability accruing to Akoda, at its sole discretion, (i) terminate/ suspend the campaign and/ or return all or part of the donations to the respective Donors and/ or, (ii) where the donations have already been disbursed to you, require you to refund all or part of the donations. Akoda may also terminate/ suspend your membership and/or use of the Website. The rights and remedies of Akoda under this section shall be in addition to its rights in law or equity.</li>
                        <li>You shall fully and accurately disclose all material facts relating to the beneficiary, purpose and campaign, and not withhold from disclosure any material fact. If any information provided by you were to, or likely to, become not true, correct or complete at any time (for example, due to expiry of permissions, change of purpose, death of beneficiary, etc.), you shall promptly notify Akoda and to Donors in writing.</li>
                        <li>If we become aware that any information provided by you is inconsistent, inaccurate, not current or incomplete, or We have reasonable grounds to suspect that such information is inconsistent, inaccurate, not current or incomplete, or not in accordance with these Terms of Use, We reserve the right to, inter alia, indefinitely suspend or terminate the Campaign and refuse access to the Website. Further, you understand that we shall not be held liable for any damage or consequences of any manner towards you or any third party as a result of the actions undertaken by Akoda under this Section 3(x) of these Terms of Use.</li>
                        <li>You shall withdraw/utilise the funds raised on our platform within 45 days from the end of campaign. Akoda reserves the right to refund/make payout to the beneficiary on it’s own in case the campaigner do not withdraw the funds within 45 days from the end date of campaign.</li>
                        <li>Akoda provides you an option to leave us a tip while withdrawing the funds. Once you leave us a tip Akoda reserves the right to utilize the funds in the manner it deems fit without any obligation to refund/pay it back to you under any circumstances.</li>
                        <li>We may require you to periodically update/re-attest your/ the beneficiary’s eligibility to receive donations, including in connection with application foreign donation laws. In this regard, you may be required to certify that the information/ documents provided by you are up to date, accurate and valid. You shall do so promptly when requested, and at your own cost. Your failure to provide updates or relevant documentations would constitute a breach by you of these terms.</li>


                    </ul>
                    <li>Donor</li>
                    <ol start={1}>
                        <li>Any person who fulfills the eligibility criteria set out in clause 2 above shall be permitted to donate an amount higher than Rs. 100 or USD 10 in value to any Campaign started/listed on the Website by any Campaigner (“Donor”).</li>
                        <li>The Donor hereby agrees to make the payments for any Campaign as per the terms and conditions stated herein.</li>
                        <li>The Donor further agrees and declares that the Donor is aware that Akoda has no responsibility to verify the accuracy of any statement and/or information furnished by the Campaigner with respect to any Campaign pursuant to which the Donor has donated moneys(s) for any Campaign via Akoda on the Website.</li>
                        <li>The donor will be sent timely updates about the progress/status of the fundraiser from both the Campaigner and Akoda via SMS and email. In case one wants to opt out of this service, they need to log into their Akoda profile and disable the communications for that campaign.</li>
                        <li>Through the Website, Donors may obtain access to certain information about the Campaigner and beneficiaries, including means of direct communication. Nothing in these terms shall be viewed as Akoda certifying that the Campaign information provided is unqualified, error-free, exhaustive and/ or warranted by Akoda. The Website affords Donors an opportunity to ask Campaigners questions concerning the Campaign and otherwise test the soundness of any campaign. While making a donation through the Website, the Donor shall conduct their independent due diligence with respect to any information/Campaign listed by a Campaigner (which includes the non- profit organization in India) before making any donations on the Website. Akoda shall not be responsible in any manner for any misrepresentation and/or false information that may be listed on the Website. The Donor should not rely on Akoda or its affiliates for the accuracy, completeness or adequacy of the information supplied on the Website. Moreover, Donors should note that neither Akoda nor any of its affiliates or employees track, represent or warrant that Campaigners or the beneficiaries use the donated funds in the manner and for the purpose represented by them.</li>
                        <li>In the event a Campaign is listed by any person on behalf of a non –profit organization in India, the Donor shall approach only the particular non- profit organization in India for which the Donor has donated the money to track the utilization of the funds or any other information that may be required by the Donor. The Donor hereby agrees and declares that the Donor is aware that Akoda shall at no point of time be held answerable or accountable for any money(s) once the funds donated have been handed over to the non-profit organization in respect of which donations have been made.</li>
                        <li>Impact Coupons - User needs to log onto Akoda.org, where the person can browse all campaigns on Akoda and contribute INR 100 to any one Campaign without paying anything using this coupon. User needs to be logged in via Facebook and can be used only once per user. Multiple usage by the same account will not be allowed.</li>
                        <li>In the event that a Campaign is terminated prematurely for any reason, including due to the death of a Campaigner or their early recovery, etc., all or a part of the donations received may be unutilized. In such cases, Akoda reserves the right to divert the unutilized donations to any other active Campaign on its Platform. Akoda shall endeavor to redeploy the unutilized contribution within 45 days of determining that it cannot be utilized for the Campaign for which it was originally received. If Akoda is unable to redeploy the contributions within the time specified, the entire amount donated will be refunded to the Donor’s account. We will notify you once the contributions have been redeployed.</li>
                        <li>Donors hereby authorize Akoda and its service providers to collect, process, facilitate and remit funds through regular banking channels, payment, clearance, and settlement systems.</li>
                        <li>Akoda reserves the right to (i) delay payments (without payment of interest), or refuse to process payments by any Donor, including for any questionable transactions, and/or (ii) terminate/suspend a campaign in relation to the said questionable transactions. This includes, without limitation, suspected collusion between the Donor and Campaigner to bring about false impressions, mislead other Donors or breach/ violate any law or further any charges imposed by issuing banks, or generally breach of any governmental policies or applicable laws.</li>
                    </ol>
                    <li>User Account and Registration Obligations</li>
                    <ul>
                        <li>By using the Website, you shall be responsible for maintaining the confidentiality of your Login Name and Password, and You shall be responsible for all activities that occur under your Login Name. You agree that if any information provided by you that is inconsistent, inaccurate, not current or incomplete, or we have reasonable grounds to suspect that such information is inconsistent, inaccurate, not current or incomplete, or not in accordance with these Terms of Use, We reserve the right to indefinitely suspend or terminate or block access of your membership on the Website and refuse you access to the Website</li>
                    </ul>
                    <li>Communications</li>
                    <ul>
                        <li>When you use the Website or send emails or other data, information or communication to Akoda, you agree and understand that You are communicating with Akoda through electronic records and you consent to receive communications via electronic records from Akoda periodically and as and when required. Akoda may communicate with you by email or by such other mode of communication, electronic or otherwise.</li>
                    </ul>
                    <li>Platform for Information, Communication and Transaction</li>
                    <ul><li>All commercial/contractual terms are offered by and agreed to between the Campaigner and Donors / Contributors alone. The commercial/contractual terms include without limitation the representations made by the Campaigner including, amounts to be raised, use of the funds raised, costs incurred, payment methods, payment terms, warranties related to the final use of the funds, the effectiveness of the utilization of the funds, the impact of the campaign, the rewards offered by the Campaigner and the contributions made by the Users. Akoda does not have any control or does not determine or advise or in any way involve itself in either determining any details of the Campaign or the offers of Campaigner or acceptance of such commercial/contractual terms between the Donors / Contributors and the Campaigner.</li>
                        <li>Akoda does not make any representation or warranty as to specifics (such as final utilization of funds, value, impact, rewards, etc.) of the campaigns on the Website, other than those representations that are expressly made for individual campaigns. Notwithstanding any representations made, Users are free to independently verify the veracity of any statement made on the Website. Akoda does not implicitly or explicitly support or endorse any of the campaigns on the Website. Akoda accepts no liability for any errors or omissions, whether on behalf of itself or any Campaigner.</li>
                        <li>Akoda is not responsible for any non-performance or under-performance of any of the commitments made by the Campaigner or for any contractual obligations (E.g. Rewards) entered into between Contributors and the Campaigner. Akoda cannot and does not guarantee that the concerned Campaigner will perform any commitment concluded on the Website. The Campaigner shall at all times be responsible for and ensure that the performance of the Rewards offered by them, in the event of any failure of the fulfilment of the obligations of the Campaigner, the same shall be settled between the parties directly and Akoda shall not be held responsible for any such default/non-performance of the obligations of the Campaigner and at no time shall Akoda be required to mediate or resolve any dispute or disagreement between Donors and Campaigner. However, Akoda does welcome feedback as well as grievances of Users to be brought to its attention so that it may take steps that it deems appropriate for improvement of the User experience on the Website.</li>
                        <li>Akoda does not make any representation or warranty as to the Campaign specifics (such as the eventual impact, use of funds, efficiency of Campaigner, etc) of any of its Campaigner. You are advised to independently verify the bona fides of any particular Campaigner that you choose to donate or contribute to, on the Website and use your best judgment in that behalf.</li>

                    </ul>
                    <li>Charges</li>
                    <ul>
                        <li>Akoda does not charge any fee for browsing on the Website. Akoda's charges are limited to the facilitation fee (including platform / collection fees and transactional charges) which is charged to the beneficiaries of the funds raised on Akoda on the terms and conditions agreed to between Akoda and the beneficiaries of the funds independently. Akoda reserves the right to change its Fee Policy from time to time. In particular, Akoda may at its sole discretion introduce new services and modify some or all of the existing services offered on the Website. In such an event Akoda reserves the right to introduce fees for the new services offered or amend/introduce fees for existing services, as the case may be.</li>
                        <li>By agreeing to the Terms of use, you agree to Akoda’s plans and pricing policy.</li>
                        <li>Akoda runs advertising campaigns on behalf of its campaigners for which it deducts the full advertising expense apart from the facilitation fee before transferring the funds to the beneficiary.</li>

                    </ul>
                    <li>General Rules for the Use of the Website</li>
                    <p>You agree, undertake and confirm that your use of Website shall be strictly governed by the following binding principles:</p>
                    <ul style={{ listStyleType: "upper-roman" }}>
                        <li> You shall not host, display, upload, modify, publish, transmit, update or share any information which:</li>

                        <ul>
                            <li>belongs to another person and to which you do not have any right to;</li>
                            <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</li>
                            <li>is misleading in any way;</li>
                            <li>is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, pedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</li>
                            <li>harasses or advocates harassment of another person;</li>
                            <li>involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</li>
                            <li>promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</li>
                            <li>infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number),rights of publicity, or sale of counterfeit/stolen items;</li>
                            <li>promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;</li>
                            <li>contains restricted or password-only access pages, or hidden pages or images (including those not directly linked to or accessible from another page);</li>
                            <li>provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</li>
                            <li>provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</li>
                            <li>contains a video, photographs, or images of another person;</li>
                            <li>tries to gain unauthorized access or exceeds the scope of authorized access to the Website or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Website or solicits passwords or personal identifying information for commercial or unlawful purposes from other users</li>
                            <li> engages in commercial activities and/or sales without Akoda’s prior express and written consent, including but not limited to contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" items related to the Website;</li>
                            <li>solicits gambling or engages in any gambling activity which could be construed as being illegal;</li>
                            <li>interferes with another user's use and enjoyment of the Website or any other individual's user and enjoyment of similar services;</li>
                            <li>refers to any website or URL that, in Akoda’s discretion, contains material that is inappropriate for the Website or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use;</li>
                            <li>harms minors in any way;</li>
                            <li>violates any law in force for the time being;</li>
                            <li>deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
                            <li>impersonates another person or entity;</li>
                            <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information; or</li>
                            <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order, or causes incitement to the commission of any cognizable offence or prevents investigation of any offence, or is insulting to any other nation.</li>


                        </ul>
                        <li>You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or any content, or in any way reproduce or circumvent the navigational structure or presentation of the Website or any content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Website. Akoda reserves the right to bar any such activity.</li>
                        <li>You shall not attempt to gain unauthorized access to any portion or feature of the Website, or any other systems, server, computer, or networks connected to the Website, , or to any of the Services offered on or through the Website, by hacking, password "mining" or any other illegitimate means.</li>
                        <li>You shall not probe, scan or test the vulnerability of the Website or any network connected to the Website, nor breach the security or authentication measures on the Website or any network connected to the Website.</li>
                        <li>You may not reverse look-up, trace or seek to trace any information on any other individual accessing the Website, or of any other customer, including any account on the Website not owned by you, to its source, or exploit the Website or any service or information made available or offered by or through the Website, in any manner, including but not limited to reveal any personal identification or information, other than your own information, as provided for by the Website.</li>
                        <li>You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about Akoda, or the brand name or domain name used by Akoda, or otherwise engage in any conduct or action that might tarnish the image or reputation of Akoda on any platform, and/or otherwise tarnish or dilute any of Akoda's trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by us.</li>
                        <li>You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Website or Akoda systems or networks, or any systems or networks connected to Akoda.</li>
                        <li>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Website or any transaction being conducted on the Website, or with any other person's use of the Website.</li>
                        <li>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to us on or through the Website or any service offered on or through the Website.</li>
                        <li>You may not use the Website or any content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of Akoda.</li>
                        <li>You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000, and rules made thereunder as applicable and as amended from time to time and also all applicable domestic laws, rules and regulations (including the provisions of any applicable Exchange Control Laws or Regulations in Force) and International Laws, Foreign Exchange Laws (specifically the Foreign Contribution Regulation Act, 2010 and the rules made thereunder), Statutes, Ordinances and Regulations (including, but not limited to Sales Tax/ VAT, Income Tax, Octroi, Service Tax, Central Excise, Custom Duty, Local Levies) regarding your use of our service. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force. Further, no donation for reward or otherwise shall be made for any reason or as consideration for any transaction other that which is the subject of the campaign, and in no event shall any donation be used for any purpose that is considered unlawful or against public policy as per the applicable laws in India.</li>
                        <li>Solely to enable us to use the information, you assign to us rights that you might have in your information; you agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights you have in your information, in any media now known or not currently known, with respect to your Information. We will only use your information in accordance with the Terms of Use and Privacy Policy applicable to the use of the Website</li>
                        <li>From time to time, you shall be responsible for providing information relating to the donations made by you and disclose it under applicable laws. In this connection, you undertake that all such information shall be accurate in all respects and that only you shall be solely responsible for any incorrect or knowingly false information provided by you.</li>
                        <li>You shall not engage in advertising to, or solicitation of, other users of the Website to Campaigns or Services, including, but not limited to, Campaigns related to those being displayed on the Website or related to us. You may not transmit any chain letters or unsolicited commercial or junk email to other users via the Website. It shall be a violation of these Terms of Use to use any information obtained from the Website in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than us without our prior explicit consent. In order to protect our users from such advertising or solicitation, we reserve the right to restrict the number of messages or emails which a user may send to other users in any 24-hour period which we deem appropriate in our sole discretion. You understand that we have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Website) as necessary to satisfy any law, regulation or valid governmental/court request/orders. This may include, without limitation, disclosure of the information in connection with the investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or summons. In addition, we can (and you hereby expressly authorize us to) disclose any information about you to law enforcement or other government officials, as we, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.</li>
                        <li>We reserve the right, but have no obligation, to monitor the materials posted on the Website by a Campaigner or generally by Users on any one of the interactive platforms provided on the Website. Akoda shall have the right to remove or edit any content that in its sole discretion that violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE WEBSITE AND IN YOUR PRIVATE MESSAGES TRANSMITTED VIA THE WEBSITE. Please be advised that such Content posted does not necessarily reflect Akoda's views. In no event shall Akoda assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the Website. You hereby represent and warrant that you have all necessary rights in and to all content which you provide and all information it contains and that such content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortuous, or otherwise unlawful information.</li>
                        <li>Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Website, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Website.</li>
                        <li>It is possible that other Users (including unauthorized users or "hackers") may post or transmit offensive or obscene materials on the Website and that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of the Website, and that the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses, but by using the Website you acknowledge and agree that we are not responsible for the use of any personal information that you publicly disclose or share with others on the Website. Please carefully select the type of information that you publicly disclose or share with others on the Website.</li>
                        <li>Akoda shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services).</li>
                        <li>Akoda provides its services to you through its Website, mobile applications and related services (collectively, such services, including any new features and applications, and the Site, the "Services"), subject to these Terms of Use (as amended from time to time, the "Terms of Use"). We reserve the right, at our sole discretion, to change or modify portions of these Terms of Use at any time. If we do this, we will notify you, either through the user interface (Website/mobile applications), in an email notification or through other reasonable means, and unless otherwise stated, Akoda may communicate with you officially by any reasonable means now known or later developed. These currently include email, regular mail, and postings on the Webite. Any such changes will become effective no earlier than fourteen (14) days after they are posted, except that (i) changes addressing new functions of the Services or changes made for legal reasons will be effective immediately, and (ii) changes or modifications to the provisions therein. Your continued use of the Services after the date any such changes become effective constitutes your acceptance of the new Terms of Use. In addition, when using certain services, you will be subject to any additional terms applicable to such services that may be posted on the services from time to time, including, without limitation, the Privacy Policy. All such terms are hereby incorporated by reference into these Terms of Use. Finally, we encourage you, particularly if you intend to donate to a Akoda campaign, to review the Charges section of these Terms of Use.</li>
                        <li> Services Description: The Services are offered as a platform (the "Platform") to users of the Services,
                            which may include Campaigners and Donors and other registered users of the Services (which may include users who simply "like"
                            campaigns or otherwise interact with the Website or Services). Among other features, the Services are designed to allow Campaigners to post a fundraising campaign
                            ("Campaign") to the Platform to accept monetary donations from those registered users wishing to contribute funds to the Campaign.
                            For purposes hereof, the term "Campaigners" shall also be deemed to include any individual(s) designated as a beneficiary of a Campaign. Although there are no
                            fees to set up a Campaign, a percentage of each donation will be charged as fees for our Services and those of our third-party payment processors.
                            Please see our Charges section for details. The Services are a Platform; we are not a Broker, Financial Institution, Creditor or Charitable Institution:
                            The Services are an administrative platform only. Akoda facilitates the donation transaction between Campaigners and Donors, but is not a party to any agreement
                            between a Campaigner and a Donor, or between any user and a Charity. Akoda is not a broker, agent, financial institution, creditor or insurer for any user.
                            Akoda has no control over the conduct of, or any information provided by a Campaigner or a charity, and Akoda hereby disclaims all liability in this regard.
                            Anyone is free to report issues related to a campaign on Akoda, and Akoda will take necessary action to the best of its abilities. Akoda does not guarantee that a
                            Campaign or a charity will obtain a certain amount of donations or any donations at all. We do not personally endorse any Campaign, Campaigner, or charity,
                            and we make no guarantee, explicit or implied, that any information provided through the Services by a user is accurate. We expressly disclaim any liability or
                            responsibility for the success of any Campaign, or the outcome of any fundraising purpose. You, as a Donor, must make the final determination as to the value and
                            appropriateness of contributing to any Campaign, Campaigner, or charity. You can write to us for any clarification, and we shall make our best effort to share the
                            information that we have. While we try our best to do so, we cannot and do not verify the information that Campaigners supply We cannot guarantee that donations will
                            be used in accordance with any fundraising purpose prescribed by a Campaigner or charity. We assume no responsibility to verify whether the donations are used in
                            accordance with any applicable laws, and such responsibility rests solely with the Campaigner or charity, as applicable. We take possible fraudulent activity and
                            the misuse of funds raised very seriously. If you have reason to believe that a Campaigner or charity is not raising or using the funds for their stated purpose,
                            please use the "Report" button on the Campaign to alert our team of this potential issue and we will investigate to the best of our abilities. You, as a campaigner,
                            represent, warrant, and covenant that (i) all information you provide in connection with a Campaign is accurate, complete, and not otherwise designed to mislead,
                            defraud or deceive any user; and (ii) all donations contributed to your Campaign will be used solely as described in the materials that you post;
                            and (iii) you will comply with all relevant local, state, and Union laws applicable to you when you solicit funds, particularly
                            but not limited to laws relating to your marketing and solicitation for your project. We reserve the right to provide information relating to your
                            Campaign with donors and beneficiaries of your Campaign, and with law enforcement or to assist in any investigation. The terms and conditions that apply to a Campaign
                            Organiser (Campaigner) are: </li>

                    </ul>
                    <ul>
                        <li>You are solely responsible for utilizing the funds for the purpose mentioned in our fund-raising page. Akoda is not responsible in any way whatsoever towards the end utilization of funds.</li>
                        <li>This fund-raising page on the Platform will be available to the general public for contributions from the time you published the page on the Platform, and if your Campaign is not rejected by Akoda. You may or may not be able to raise the targeted amount in the designated period. Akoda can reject your fundraiser for inappropriate or insufficient content, at its sole discretion.</li>
                        <li>Akoda will not be responsible in any way whatsoever on the amount of contributions received. It will be your sole responsibility to address any query on objection, from a donor, in regards to utilisation of funds.</li>
                        <li>Akoda will collect contributions online, and will send the contributions to your designated bank account upon completion of statutory verification. You can raise withdrawal requests post approval of the Campaign by Akoda. Akoda will process fund transfer, post receipt of your withdrawal request.</li>
                        <li>You understand that Akoda or its affiliates do not own the funds. At the time of transfer of funds, you will receive contributions net of Akoda fees, facilitation fees, applicable taxes, or any other fees mutually agreed upon between you and Akoda. For a check, please look at the fees in our Charges section.</li>
                        <li>In case of contributions outside India, you agree that you have the necessary regulatory permissions to receive such funds, Such permissions include but are not limited to the Foreign Contributions Regulation Act, 2010 (FCRA), and others mandated my regulatory authorities.</li>
                        <li>If you are an organization, eligible to issue tax deduction certificates to your donors, Akoda will facilitate the process through its proprietary software application on your behalf. However, it is your sole responsibility to ensure that you have the necessary approvals and certificates to issue such tax-deductible receipts to your donors. Akoda will not have any liability, in any form whatsoever, in this regard.</li>
                        <li>You hereby indemnify and agree to keep indemnified and hold safe and harmless Akoda, its directors, employees and agents against all such liabilities or claims , losses, damages, taxes, costs (including for Campaign termination/ suspension/ reimbursement, investigations, counsel fees, etc.) that may arise on Akoda, its directors, employees, controlling persons and agents on account of acts of (i) an omission, (ii) any actual or alleged misuse, waste, abuse or theft of any moneys raised in any Campaign by you or the beneficiary or utilization of funds by you or the beneficiary on anything other than the stated purpose, (iii) lack of necessary regulatory approvals for receipt of funds in any form or for carrying out the activities required for execution of the Purpose.; (iv) any untrue statement or alleged untrue statement of a material fact contained in any document, furnished by you or the beneficiary, directly or through Akoda, to any person or Donor or the omission or the alleged omission to state or disclose a material fact necessary in order to make the statements therein, in light of the circumstances under which they were made, not misleading, or (ii) any breach of applicable law by You or the beneficiary, including for non-payment of taxes, if any, due; or (iii) any third party claims made against Akoda due to your non-compliance or Campaign being terminated/ suspended and/or refund of donations by you. The indemnities if any shall survive beyond the period of the Terms of Use. Please read details of the indemnities in a section below in this Terms of Use.</li>
                        <li>In no event will Akoda be liable for any indirect, incidental or consequential damages (including loss of profit or business), howsoever arising, whether under contract, tort or otherwise, even if informed of the possibility of the same.</li>

                    </ul>
                    <li>You, on behalf of yourself, the beneficiary and each of your respective representatives irrevocably and unconditionally, knowingly and voluntarily:</li>
                    <ul>
                        <li>Authorize Akoda and any service provider or investigative agency retained by Akoda, to check, verify, investigate or otherwise obtain information about you and the beneficiary’s clearance under applicable anti-money laundering, crime or terrorist financing or sanctions lists, including those maintained by India, the United States and other countries having Donors, and background, including criminal background search, civil litigation search, credit report, driving record, worker’s compensation claim history, address verification, identity verification, need verification, verification of health, claim history and insurance records, among others;</li>
                        <li>Authorize any company, governmental authority, court, law enforcement agency, health services provider, insurance company, or any other person contacted by Akoda or its representatives (including any service provider or investigative agency retained by any of them) to furnish to Akoda or its representatives, as the case may be, copies of any and all documents, records or information in their custody or control relating to you and the beneficiary; and</li>
                        <li>Release Akoda, as well as any persons acting on its behalf, from any and all claims, losses and liability arising from the foregoing.
                            You acknowledge and understand that (i) nothing contained in this section or these terms is intended to impose on Akoda any initial or continuing obligation to conduct or monitor or investigate your and/ or the beneficiary’s background, and (ii) in the event in the course of its investigations or otherwise, Akoda were to come into possession of any information relating to the commission of (or any scheme, stratagem or conspiracy for the commission of) any breach of applicable laws or crime by you or the beneficiary, including but not limited to any crime relating to misrepresentation/fraud, forged documents, money laundering, criminal or terrorist financing, unlawful procurement of foreign funds, then in any such event, Akoda may take steps as per applicable laws including inform any governmental authority and otherwise cooperate with governmental authorities without any obligation to first or subsequently notify you or the beneficiary.</li>

                    </ul>
                    <li>Contents Posted on Site</li>
                    <ul style={{ listStyleType: "lower-roman" }}>
                        <li>All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music and artwork (collectively, "Content"), is third party user generated content and Akoda has no control over such third party user generated content, as Akoda is merely an intermediary for the purposes of these Terms of Use.</li>
                        <li>Except as expressly provided in these Terms of Use, no part of the Website and no content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any other computer, server, website or other medium for publication or distribution or for any commercial enterprise, without Akoda's express prior written consent.</li>


                    </ul>
                    <li>Limited License</li>
                    <p> Akoda grants Campaigners a limited, non-transferable, non-exclusive, non-sub licensable, non-assignable and personal license to use "Funds raised through Akoda", "Powered by Akoda" and/or "via Akoda" name and/or logo on Campaigners' invoice / certificate of donation for transactions concluded on the Website. Further, Akoda grants Campaigners a limited, non-transferable, non-exclusive, non-sub licensable, non-assignable and personal license to use "Akoda.org" name and/or logo on stationery used by the Campaigner for delivery of communications / receipts / certificates, etc., through the Website.</p>
                    <li>Linked Websites / APIs/Social Networking Sites</li>
                    <ul style={{ listStyleType: "lower-roman" }}>
                        <li>Akoda does not take responsibility or liability for the actions, products, content and services on the Website, which are linked to affiliates and / or third party websites using the Website's APIs or otherwise. In addition, the Website may provide links to third party websites of our affiliated companies and certain other businesses for which, Akoda assumes no responsibility for examining or evaluating the products and services or generally information offered by them. Akoda does not warrant the offerings of, any of these businesses or individuals or the content of such third party website(s). Akoda does not endorse, in any way, any third party website(s) or content thereof.</li>
                        <li>The Services may provide or facilitate, or third parties may provide, links or other access to other sites, services and resources on the Internet. Akoda has no control over such sites, services and resources and Akoda is not responsible for and does not endorse such sites, services and resources. You further acknowledge and agree that Akoda will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, events, goods or services available on or through any such site, service or resource.</li>
                        <li>Any dealings you have with third parties found while using the Services are between you and the third party, and you agree that Akoda is not liable for any loss or claim that you may have against any such third party.</li>
                        <li>You may enable or log into the Services via various online third party services, such as social media and social networking services like Facebook or Twitter ("Social Networking Services"). By logging in or directly integrating these Social Networking Services into the Services, we make your online experiences richer and more personalised. To take advantage of this feature and capabilities, we may ask you to authenticate, register for or log into Social Networking Services on the websites of their respective providers. As part of such integration, the Social Networking Services will provide us with access to certain information that you have provided to such Social Networking Services, and we will use, store and disclose such information in accordance with our Privacy Policy. For more information about the implications of activating these Social Networking Services and Akoda's use, storage and disclosure of information related to you and your use of such services within Akoda (including your friend lists and the like), please see our Privacy Policy. However, please remember that the manner in which Social Networking Services use, store and disclose your information is governed solely by the policies of such third parties, and Akoda shall have no liability or responsibility for the privacy practices or other actions of any third party site or service that may be enabled within the Services.</li>
                        <li>In addition, Akoda is not responsible for the accuracy, availability or reliability of any information, content, goods, data, opinions, advice or statements made available in connection with Social Networking Services. As such, Akoda is not liable for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such Social Networking Services. Akoda enables these features merely as a convenience and the integration or inclusion of such features does not imply an endorsement or recommendation.</li>


                    </ul>

                    <li>Privacy</li>
                    <p> We view protection of your privacy as a very important principle. We understand clearly that you and your Personal Information is one of our most important assets. We store and process Your Information including any sensitive personal information or data collected (as defined under the Information Technology Act, 2000), if any, on computers that may be protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act 2000 and rules there under. Our current Privacy Policy is available here https://www.Akoda.org/privacy-policy.php If you object to your Information being transferred or used in this way please do not use the Website.</p>
                    <li>Disclaimer of Warranties and Liability</li>
                    <ul style={{ listStyleType: "lower-roman" }}>
                        <li>This Website, all the materials and information (including but not limited to campaigns) and Services, included or otherwise made available to you through this site are provided on a "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the forgoing paragraph, Akoda does not warrant that:</li>
                        <ul>
                            <li>This Website will be constantly available, or available at all; or</li>
                            <li>The information on this Website is complete, true, accurate or non-misleading save and except as provided herein and which information is provided by the Campaigner themselves and none that which is provided or verified by Akoda</li>

                        </ul>
                        <li>Akoda will not be liable to you in any way or in relation to the contents of, or use of, or otherwise in connection with, the Website. Nothing on the Website constitutes, or is meant to constitute, advice of any kind.</li>
                        <li>You will be required to enter a valid phone number and e-mail address while making a donation / contribution on the Website. By registering your phone number and e-mail address with us, You consent to be contacted by us and/or the Campaigner via phone calls and / or SMS notifications, in case of any reward or delivery related updates. We will not use your personal information to initiate any promotional phone calls or SMS unless the same is consented to by you. YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. AKODA AND ITS AFFILIATES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. AKODA AND ITS AFFILIATES MAKE NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.</li>

                    </ul>
                    <li>Financial Transaction Facility - Donors / Contributors</li>
                    <ul style={{ listStyleType: "lower-roman" }}>
                        <li>While availing any of the payment / donation / contribution method/s available on the Website, we will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due to:</li>
                        <ul>
                            <li>Lack of authorization for any transaction/s, or</li>
                            <li>Exceeding the preset limit mutually agreed by you and between your "bank/s", or</li>
                            <li>Any payment issues arising out of the transaction, or</li>
                            <li>Decline of transaction for any other reason/s</li>

                        </ul>
                        <li>All payments made towards the donations / contributions on the Website by you, shall be compulsorily in Indian Rupees acceptable in the Republic of India if you are an Indian National and using an Indian payment gateway. All payments made towards the donations / contributions on the Website by you, shall be compulsorily through the Foreign Payment Gateway if you are making payment in any currency other than INR. The Website will not facilitate transactions through the Indian Payment Gateway with respect to the donations / contributions made on Website if you are not an Indian national.</li>
                        <li>You have categorically authorized Akoda or its service providers (like but not limited to payment gateways, banks and marketplace settlement providers like, PayU, Razorpay, Stripe, Billdesk, Paytm, HDFC Bank, RBL, Bank, Gocashfree) to collect, process, facilitate and remit payments and / or the transaction price electronically or through Gharpay (or any other authorized agent) to and from other Users in respect of transactions through payment facility. Your relationship with Akoda is on a principal to principal basis and by accepting these Terms of Use you agree that Akoda is an independent contractor for all purposes, and does not have control of or liability for the campaigns that are listed on the Website wherein money is transacted by using the payment facility.</li>
                        <li>You understand, accept and agree that the payment facility provided by Akoda is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment through, collection and remittance facility for the Transactions on the Website using the existing authorized banking infrastructure, card payment gateway networks and settlement service providers. Further, by providing payment facility, Akoda is neither acting as a trustee nor acting in a fiduciary capacity with respect to the transaction or the transaction price.</li>
                        <li>Akoda reserves the right to refuse to process Transactions by Donors / Contributors with a prior history of questionable transactions including without limitation to collusion between the Donor and Campaigner to bring about a false impression or mislead other Users of the Website or breach / violation of any law or any charges imposed by Issuing Bank or breach of any policy.</li>
                        <li>Akoda may delay notifying the payment / transaction confirmation, i.e., informing the Campaigner, if Akoda deems the transaction to be suspicious. In addition, Akoda may hold the transaction price / amount and inform law enforcement officials (instead of refunding the same to Donor) at the request of law enforcement officials or in the event the Donor / contributor or Campaigner for that matter, is engaged in any form of illegal activity.</li>
                        <li> The Donor / Contributor acknowledge that Akoda will not be liable for any damages, interests or claims etc. resulting from not processing a transaction / transaction price or any delay in processing a transaction / transaction price which is beyond control of Akoda.</li>
                        <li>Akoda shall make payments, net of its costs, charges and fees into the bank account provided by the Campaigner during Campaigner registration process. Once Akoda has made payments into such bank account number Akoda shall be discharged of any/all liability towards the Campaigner and contributors/donors and the Campaigner, donors, contributors shall not be eligible for any claims and/or refunds thereof.</li>

                    </ul>
                    <li>Financial Transaction Facility - Campaigner</li>
                    <ul style={{ listStyleType: "lower-roman" }}>
                        <li>You, as a Campaigner, shall be required to register yourself with Akoda and provide Akoda with all the necessary information as may be deemed by Akoda from time to time including but not limited to banking details / income tax details such as PAN and registration certificates under various provisions and laws, etc. Further, you shall fulfill the commitments made to the Donor / contributor in the manner as detailed in the Campaign and in a timely manner. Akoda may seek information regarding the use of the funds raised by the Campaigner even after the campaign has ended and provide the details to the Donors / contributors.</li>
                        <li>The funds collected towards each campaign shall be transferred to you (net of Akoda Fees, payment gateway charges and applicable taxes) as per the details provided by you after 15 days of completion of the campaign or as per withdrawal request. If at any time either during or after the campaign, you are unable to fulfill your commitments, or out of requirement under law, you shall return the money to the Donor / Contributor within a timely manner.</li>
                        <li>Remittances to the Campaigner for their successful transactions under payment facility excluding cheque payments transactions would be in compliance with applicable directions issued by Reserve Bank of India. Remittance to the Campaigner for cheque donations / contributions shall be made through cheque/ demand draft or online bank transfer to Campaigner bank a/c.</li>
                        <li>Akoda shall settle all amounts net of its fees to the accounts of the Campaigner, in accordance with the applicable guidelines the Reserve Bank of India.</li>
                        <li>If for any reason there is a chargeback on a transaction/donation to Akoda and if the funds are already transferred by Akoda to the Campaigner/beneficiary the Campaigner/beneficiary will be liable to refund the amount that has been charged back to Akoda.</li>

                    </ul>
                    <li>Compliance with Laws:<p>The Donor / Contributor shall comply with all the applicable laws (including without limitation Foreign Exchange Management Act, 1999 and the rules made and notifications issued there under and the Exchange Control Manual as may be issued by Reserve Bank of India from time to time, Customs Act, Information and Technology Act, 2000 as amended by the Information Technology (Amendment) Act 2008, Prevention of Money Laundering Act, 2002 and the rules made there under, Foreign Contribution Regulation Act, 2010 and the rules made there under, Income Tax Act, 1961 and the rules made there under, Export Import Policy of government of India) applicable to them respectively for using the payment facility and Website.</p></li>

                    <li>Donor's / Contributors arrangement with Issuing Bank:</li>
                    <ul>
                        <li>All valid credit/debit/cash card and other payment instruments are processed using a card payment gateway or appropriate payment system infrastructure and the same will also be governed by the terms and conditions agreed to between the Donor / contributor and the respective issuing bank and payment instrument issuing company.</li>
                        <li>All online bank transfers from valid bank accounts are processed using the gateway provided by the respective issuing bank, which support payment facility to provide these Services to the Users. All such online bank transfers on payment facility are also governed by the terms and conditions agreed to between the Donor / contributor and the respective issuing bank.</li>

                    </ul>
                    <li>Breach</li>
                    <ul style={{ listStyleType: "lower-roman" }}>
                        <li>Without limiting other remedies, we may limit your activity, immediately remove your information, warn other Users of your actions, immediately temporarily/indefinitely suspend or terminate or block Your membership, and/or refuse to provide you with access to the Website in the event, but not limited to:</li>
                        <ul>
                            <li>If You breach the Terms of Use or Privacy Policy or other policies, if any;</li>
                            <li>If we are unable to verify or authenticate any information you provide; or</li>
                            <li>If it is believed that your actions may cause legal liability for you, other users or us.</li>


                        </ul>
                        <li> We may at any time at our sole discretion reinstate suspended users. A User that has been suspended or blocked may not register or attempt to register with us or use the Website in any manner whatsoever until such time that such user is reinstated by us. Notwithstanding the foregoing, if you breach the Terms of Use or Privacy Policy or other rules and policies, we reserve the right to recover any amounts due and owing by you to us and to take strict legal action including but not limited to a referral to the appropriate police or other authorities for initiating criminal or other proceedings against you.</li>
                    </ul>

                    <li>Indemnity</li>
                    <ul>
                        <li>The Campaigner shall indemnify and hold harmless Akoda, its owner, licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees (”Indemnified Parties,”), against all claim, actions, penalties, prosecution, proceedings demand losses, disputes, charges, penalty, costs and expenses including reasonable attorneys' fees, that may arise or may be incurred by the Indemnified Parties as a consequence of any default, breach, non-observance, non-performance or negligent act whatsoever by the Campaigner (or its beneficiary) of any terms, conditions, stipulations, provisions and/or violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party more particularly stated herein and/or in any individual agreements executed by Akoda and the User.</li>
                        <li>You agree to release, indemnify and hold Akoda and its affiliates and their officers, employees, directors and agents harmless from any from any and all losses, damages, expenses, including reasonable attorney’s fees, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of the Services, any donation, any loan, any campaign, any User Content, your connection to the Services, your violation of these Terms of Use or your violation of any rights of another.</li>
                    </ul>
                    <li>Waiver</li>
                    <ul><li>The User hereby agree and state that Akoda is only a service provider to enable non profit organizations in India and Campaigners to market/publicize/create awareness/advertise their respective cause/campaigns and Akoda shall in no way be responsible for the performance and/or liable for any mis-representation of any information provided by the Campaigner in any manner whatsoever.</li></ul>
                    <li>Modifications and Alterations</li>

                    <ul>
                        <li>The User shall be entitled to avail the service of Akoda via its Website and otherwise subject to the terms, conditions, provisions and stipulations mentioned in this Terms of Use and/or any other document, agreement or writing executed/ entered into by Akoda and the User individually and any modifications alterations made therein by Akoda from time to time.</li>
                        <li>Further, Akoda shall at its discretion be entitled to modify the terms and conditions of the terms of use and qualifications and conditions mentioned herein. The User shall be bound by the rules/regulations/qualifications/conditions (as may be altered) at all times.</li>
                    </ul>
                    <li>Intellectual property rights</li>
                    <p> Services Content, Software and Trademarks: you acknowledge and agree that the Services may contain content or features ("Services Content") that are protected by copyright, patent, trademark, trade secret or other proprietary rights and laws. Except as expressly authorised by Akoda, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the Services or the Services Content, in whole or in part, except that the foregoing does not apply to your own User Content (as defined below) that you legally upload to the Services. In connection with your use of the Services you will not engage in or use any data mining, robots, scraping or similar data gathering or extraction methods. If you are blocked by Akoda from accessing the Services (including by blocking your IP address), you agree not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP address). Any use of the Services or the Services Content other than as specifically authorized herein is strictly prohibited. The technology and software underlying the Services or distributed in connection therewith are the property of Akoda, our affiliates and our partners (the "Software"). You agree not to copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right in the Software. Any rights not expressly granted herein are reserved by Akoda.
                        The Akoda name and logos are trademarks and service marks of Akoda (collectively the "Akoda Trademarks"). Other company, product, and service names and logos used and displayed via the Services may be trademarks or service marks of their respective owners who may or may not endorse or be affiliated with or connected to Akoda. Nothing in this Terms of Use or the Services should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of Akoda Trademarks displayed on the Services, without our prior written permission in each instance. All goodwill generated from the use of Akoda Trademarks will inure to our exclusive benefit.
                        Third Party Material: Under no circumstances will Akoda be liable in any way for any content or materials of any third parties (including users and Campaign Organizers), including, but not limited to, for any errors or omissions in any content, or for any loss or damage of any kind incurred as a result of the use of any such content. You acknowledge that Akoda does not pre-screen content, but that Akoda and its designees will have the right (but not the obligation) in their sole discretion to refuse or remove any content that is available via the Services. Without limiting the foregoing, Akoda and its designees will have the right to remove any content that violates these Terms of Use or is deemed by Akoda, in its sole discretion, to be otherwise objectionable.
                        To be effective, the notification must be in writing and contain the following information:</p>
                    <ul>
                        <li>an electronic or physical signature of the person authorised to act on behalf of the owner of the copyright or other intellectual property interest;</li>
                        <li>a description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
                        <li>a description of where the material that you claim is infringing is located on the Services, with enough detail that we may find it on the Services;</li>
                        <li>your address, telephone number, and email address;</li>
                        <li>a statement by you that you have a good faith belief that the disputed use is not authorised by the copyright or intellectual property owner, its agent, or the law;</li>
                        <li>a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorised to act on the copyright or intellectual property owner's behalf.</li>

                        <p> Counter-Notice: If you believe that your User Content that was removed (or to which access was disabled) is not infringing, or that you have the authorisation from the copyright owner, the copyright owner's agent, or pursuant to the law, to upload and use the content in your User Content, you may send a written counter-notice containing the following information to the Copyright Agent:</p>
                        <li>your physical or electronic signature;</li>
                        <li>identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled;</li>
                        <li>a statement that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content; and</li>
                        <li>your name, address, telephone number, and email address, a statement that you consent to the jurisdiction of the federal court located within Mumbai, Maharashtra, India and a statement that you will accept service of process from the person who provided notification of the alleged infringement.
                            If a counter-notice is received, Akoda will send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed content may be replaced, or access to it restored, in 10 to 14 business days or more after receipt of the counter-notice, at our sole discretion.
                            Repeat Infringer Policy: In accordance with the DMCA and other applicable law, Akoda has adopted a policy of terminating, in appropriate circumstances and at Akoda's sole discretion, users who are deemed to be repeat infringers. Akoda may also at its sole discretion limit access to the Services and/or terminate the memberships of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</li>


                    </ul>
                    <li>Applicable Law & Jurisdiction</li>
                    <ul>
                        <li>Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. By agreeing to these Terms of Use you expressly agree that the place of jurisdiction shall be exclusively in Mumbai. Akoda is based in India in all respects. The Campaigns that are hosted by Akoda are also based in India. The Website may be accessed by persons residing / living in countries other than India as well. Akoda welcomes Foreign Donors / Contributors to participate in campaigns by making donations and contributions which shall be subject to the existing Indian laws. Those who choose to access this site from other locations/Countries other than India do so on the understanding that all transactions are bipartite in nature between the Campaigner and the Donor / Contributor. Akoda shall not be held liable for any dispute between the two parties. For any disputes involving Akoda whether as a formal party or otherwise, the jurisdiction shall exclusively be with the courts in Mumbai, India.</li>
                    </ul>
                    <li> Dispute Resolution</li>
                    <ul style={{ listStyleType: "lower-roman" }}>
                        <li>Any dispute involving Akoda, whether as a formal party or otherwise shall be resolved by arbitration of a sole arbitrator appointed by Akoda and in accordance with the Indian Arbitration and Conciliation Act, 1996. The seat of arbitration shall be in Mumbai and the language to be used in all proceedings shall be English.</li>
                        <li>Akoda and you agree to arbitrate all disputes and claims between us. This agreement to arbitrate is intended to be broadly interpreted. It includes, but is not limited to:</li>
                        <ul>
                            <li>claims arising out of or relating to any aspect of the relationship between us, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory;</li>
                            <li>claims that arose before this or any prior agreement (including, but not limited to, claims relating to advertising);</li>
                            <li>claims that are currently the subject of purported class action litigation in which you are not a member of a certified class; and</li>
                            <li>claims that may arise after the termination of this Terms of Use.</li>


                        </ul>
                        <p>References to "Akoda", "we", "you", and "us" include our respective subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of Services or devices under this or prior agreements between us. This arbitration provision shall survive termination of this Terms of Use.</p>
                    </ul>


                    <li>Limitation of Liability</li>
                    <p>IN NO EVENT SHALL AKODA BE LIABLE FOR ANY INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE TERMS OF USE. YOU EXPRESSLY UNDERSTAND AND AGREE THAT NEITHER AKODA NOR ITS AFFILIATES WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF AKODA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM:<br></br> (I) THE USE OR THE INABILITY TO USE THE SERVICE;<br></br> (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE;<br></br> (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;<br></br> (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR <br></br>(V) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT WILL AKODA'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID AKODA IN THE LAST SIX (6) MONTHS, OR, IF GREATER, TWO THOUSAND INDIAN RUPEES (RS. 2000).
                        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.27. Anti-Money Laundering (AML) policy</p>

                    <ol start={1}>
                        <li>We comply with applicable Anti-Money Laundering (AML) guidelines established by the Reserve Bank of India, vide it's circular RPCD.RRB.RCB.AML.BC.No.02/07.51.018/2014-15 on 1 July, 2014 and updated from time to time.</li>
                        <li>Every recipient (Campaigner on Akoda) has to verify her identity per the Know Your Customer (KYC) in the guidelines. This will include verification of registered mobile number via a One Time Password (OTP), and one of the identification documents, viz., Permanent Account Number, Aadhar Card Number, Passport, etc., recognised by the Reserve Bank of India. The list of such registered documents will be updated on the Website, at Akoda’s sole discretion, from time to time.</li>


                        <p>   The verification will be essential to withdraw funds from Akoda, and may be asked by Akoda, at its sole discretion, during the course of a campaign. Failure to comply, may result in cancellation of a fundraising campaign, and funds may be returned to Donors in such a scenario.
                            Your location as reflected on your campaign page must match your legal residence that you submit to Akoda when you create a withdrawal request. Campaigners may not create multiple campaigns without proof of meeting fulfilment obligations from the original campaign. Failure to adequately fulfil campaign obligations may result in Akoda removing the campaign(s) and seeking reimbursement of campaign funds raised, including by using third-party collections services. Only the Campaigners shall be permitted to collect campaign funds.
                            Campaigners are responsible for collecting and remitting any taxes on contributions, and any taxes due in connection with Campaigners may not use a false email address to self-fund, or contribute to, their own campaigns; this is a violation of our Terms of Use.
                            Akoda may attempt to verify the identity and other information provided to us by Campaigners, and we may delay, withhold, reverse or refund any contributions or other amounts without notice or liability in the event we are unable to verify any such information to our satisfaction. Campaigners’ eligibility is subject to Akoda’s review of the fulfilment status of prior crowdfunding obligations.
                            Taxing authorities may classify funds raised as taxable income to the Campaigner and any beneficiary who will receive funds directly from the applicable campaign. Akoda shall not be liable for any taxes payable on the contributions received. Akoda will ask for the personal account number / tax identification number (e.g., PAN) of Campaigners and any beneficiaries so that we may report taxable income to the relevant taxing authorities.
                        </p>
                        <li>For contributors on Akoda.org, only standard channels of payment - verified credit or debit cards, net banking, cheque, or cash (up to limits allowed by the RBI) - will be allowed. In case of cash payments, all payments above INR 24,999 have to be furnished with the Permanent Account Number of the contributor.
                            Recipients (campaign organisers, whether individuals or organizations) must comply with foreign exchange rules, as explained by the Foreign Exchange Management Act, 1999, of the RBI & FCRA guidelines. The recipient if an individual or a non-government organization also understands that it needs to comply with FCRA guidelines by applying for an FCRA certificate and inform the Central Government in Form FC-1 within thirty days from the date of receipt of such contribution whenever applicable. This form is available on the website.
                            The recipients acknowledge and agree that Akoda does not verify this information and it’s the responsibility of the recipient to procure the necessary approvals and do the necessary compliance before they withdraw the foreign funds received for the campaign. By asking to withdraw the foreign funds its understood by Akoda that the recipient has completed the necessary documentation.</li>
                        <li>AML policies will be updated time to time at the sole discretion of Akoda.</li>
                    </ol>
                    <li>Anti-Money Laundering (AML) policy</li>
                    <p> We comply with the Anti-Money Laundering (AML) guidelines established by the Reserve Bank of India, vide it's circular RPCD.RRB.RCB.AML.BC.No.02/07.51.018/2014-15 on 1 July, 2014 and updated from time to time. The guidelines include, but is not limited to, the following salient conditions:</p>
                    <ol start={1}>
                        <li>Every recipient (campaign organisers on Akoda) has to verify her identity per the Know Your Customer (KYC) in the guidelines. This will include verification of registered mobile number via a One Time Password (OTP), and one of the identification documents viz. Permanent Account Number, Aadhar Card Number, Passport etc. recognised by the Reserve Bank of India. The list of such registered documents will be updated on Akoda.org site, at its sole discretion, from time to time.
                            The verification will be essential to withdraw funds from Akoda, and may be asked by Akoda, at its sole discretion, during the course of a campaign. Failure to comply, may result in cancellation of a fundraising campaign, and funds may be returned to donors in such a scenario.
                            Your location as reflected on your Campaign page must match your legal residence that you submit to Akoda when you create a withdrawal request. Campaign Owners may not create multiple Campaigns without proof of meeting fulfillment obligations from the original Campaign. Failure to adequately fulfill Campaign obligations may result in Akoda removing the Campaign(s) and seeking reimbursement of Campaign funds raised, including by using third-party collections services. Only the Campaign Owner shall be permitted to collect Campaign funds.
                            Campaign Owners are responsible for collecting and remitting any taxes on Contributions, and any taxes due in connection with Campaign Owners may not use a false email address to self-fund, or contribute to, their own Campaigns; this is a violation of Stripe policies and of our Terms of Use.
                            Akoda may attempt to verify the identity and other information provided to us by Campaign Owners, and we may delay, withhold, reverse or refund any Contributions or other amounts without notice or liability in the event we are unable to verify any such information to our satisfaction. Campaign Owner eligibility is subject to Akoda’s review of the fulfillment status of prior crowdfunding obligations.
                            Taxing authorities may classify funds raised on the Services as taxable income to the Campaign Owner and any beneficiary who will receive funds directly from the applicable Campaign. Akoda will ask for the personal account number / tax identification number (PAN) of Campaign Owners and any beneficiaries so that we may report taxable income to the relevant taxing authorities.</li>
                        <li>For contributors on Akoda.org, only standard channels of payment - verified credit or debit cards, netbanking, cheque, or cash (upto limits allowed by the RBI) - will be allowed. In case of cash payments, all payments above INR 24,999 have to be furnished with the Permanent Account Number of the contributor.
                            Recipients (campaign organisers, whether individuals or organizations) will comply with foreign exchange rules as explained by the Foreign Exchange Management Act of the RBI & FCRA guidelines. The recipient if an individual or an non-government organization also understands that it needs to comply with FCRA guidelines by applying for an FCRA certificate and inform the Central Government in Form FC-1 within thirty days from the date of receipt of such contribution whenever applicable. This form is available on the website
                            The recipients acknowledge and agree that Akoda does not verify this information and it’s the responsibility of the recipient to procure the necessary approvals and do the necessary compliance before they withdraw the foreign funds received for the campaign. By asking to withdraw the foreign funds its understood by Akoda that the recipient has completed the necessary documentation.</li>
                        <li>AML policies will be updated time to time at the sole discretion of Akoda.</li>

                    </ol>
                    <li> Termination</li>
                    <p> You agree that Akoda, in its sole discretion, may suspend or terminate your account (or any part thereof) or use of the Services and remove and discard any content within the Services, for any reason, including, without limitation, for lack of use or if Akoda believes that you have violated or acted inconsistently with the letter or spirit of these Terms of Use. Any suspected fraudulent, abusive or illegal activity that may be grounds for termination of your use of Services, may be referred to appropriate law enforcement authorities. Akoda may also in its sole discretion and at any time discontinue providing the Services, or any part thereof, with or without notice. You agree that any termination of your access to the Services under any provision of this Terms of Use may be effected without prior notice, and acknowledge and agree that Akoda may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Services. Further, you agree that Akoda will not be liable to you or any third party for any termination of your access to the Services.</p>
                    <li> User disputes</li>
                    <p> You agree that you are solely responsible for your interactions with any other user in connection with the Services and Akoda will have no liability or responsibility with respect thereto. Akoda reserves the right, but has no obligation, to become involved in any way with disputes between you and any other user of the Services.
                    </p>
                    <li>General</li>
                    <p>These Terms of Use constitute the entire agreement between you and Akoda and govern your use of the Services, superseding any prior agreements between you and Akoda with respect to the Services. You also may be subject to additional terms and conditions that may apply when you use affiliate or third party services, third party content or third party software. The failure of Akoda to exercise or enforce any right or provision of these Terms of Use will not constitute a waiver of such right or provision. If any provision of these Terms of Use is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms of Use remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Services or these Terms of Use must be filed within one (1) year after such claim or cause of action arose or be forever barred. A printed version of this agreement and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. You may not assign these Terms of Use without the prior written consent of Akoda, but Akoda may assign or transfer these Terms of Use, in whole or in part, without restriction. The section titles in these Terms of Use are for convenience only and have no legal or contractual effect.
                        At Akoda, we respect the privacy of our users. For details please see our Privacy Policy. By using the Services, you consent to our collection and use of personal data as outlined therein. Also, you are willingly giving Akoda permission to contact you via WhatsApp, Email, SMS, and other modes of communication.
                    </p>
                    <li>Contact Us</li>
                    <p> Please send any questions or comments regarding this Website to info@Akoda.org</p>
                    <li>Grievance officer</li>
                    <p>  In accordance with Information Technology Act, 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:</p><br></br>

                    <p>  (concernedAkoda Officer’s details )</p>

                    <Link to="/contact-us" >Contact Us</Link>
                </ol>
            </div>
       
        </div>
    )
}

export default TermsAndConditions