import React from 'react'

import food_donation_event from '../Component/EventImages/orphan_food_Donation.png'

import savePlants from '../Component/EventImages/savePlants.jpg'
import facebook from './assets/images/events/facebook.png'
import { Link } from 'react-router-dom'


const FoodDonationEvent = () => {
    { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>
  

    <div className="section-title section-title-left" >

        <span className="container" style={{ color: 'rgb(17, 15, 15)', fontSize: '32px' }}>
       Food Donation Event </span>
    </div>



    
    <section className="evet-details-area pt-100 pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="event-details-content ">
                        <div className="event-details-img">
                            <img src={food_donation_event} alt="Image" />
                            <span className="date">12 <br /> Dec</span>
                        </div>

                        <div className="event-top-content">
                            <div className="event-content" style={{textAlign:'justify'}}>
                              


                                <h3>Stand up with Akoda for food donation event</h3>
                                <p>Distribution food to needy orphans & senior citizens and mentally disabled people at the Nalgonda District Telangana.
The food donation program was organized at various places with the donation of Mr. Kranthi Kumar. Through Swarna Foundation association with Akoda foundation under the “Feed the hunger” Initiative. Food was distributed to the needy orphans & senior citizens at the Nalgonda District Telangana.
Food Donation Initiative was sponsored by Mr.Kranthi Kumar on the remembrance of death anniversary of Swarna . 
Responsibilities in Nalgonda were carried out by Shri Kranthi Kumar.
A total of  hundred people were benefited through today’s initiative.

Thanks to Akoda Team.
</p>

                               

                                

                                <div className="gap-mb-20"></div>

                          
                            </div>
                        </div>
                    </div>
                </div>



                <div className="col-lg-4">
                    <div className="widget-sidebar ml-15">
                        <div className="sidebar-widget mt-0" >
                            <form className="choose-us-from-bg">


                                <div className="others-options" style={{ padding: '2px', paddingBottom: '37px' }}>
                                    <ul>
                                        <li>
                                            <Link to="/donate-now" className="default-btn border-radius-btn"
                                                style={{ padding: '15px', fontSize: '20px', marginTop: '-25px', borderRadius: '0px' }}>
                                                Donate Now
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <a href="https://www.facebook.com/profile.php?id=100079026490521" target="_blank">
                                    <div className="others-options"
                                        style={{ border: '2px solid #344E86', padding: '18px 15px 15px 15px', borderRadius: '10px', backgroundColor: '#344E86', paddingLeft: '43px' }}>
                                        <img src={facebook} height="25px" width="25px" alt="" />
                                        <span
                                            style={{ fontWeight: 'bold', color: 'rgb(255, 248, 248)', paddingLeft: '10px' }}>Spread
                                            the word (780 shares)</span>
                                    </div>
                                </a>


                            </form>
                        </div>
                        <div className="text-center">
                        <img src={savePlants}  alt="" style={{height:'300px',textAlign:'center'}}/>
                        </div>
                     

                        <div className="sidebar-widget">
                            <h3>Event location</h3>

                            <div className="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.390700423798!2d78.3803008153709!3d17.441004405846062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e1bef1173f%3A0xedf7c46288fdf31a!2sKTC%20Illumination%2C%20Vittal%20Rao%20Nagar%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1663236626782!5m2!1sen!2sin"></iframe>
                                 </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  


</div>
  )
}

export default FoodDonationEvent