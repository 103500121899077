import React, { useRef, useState } from "react";

import im10 from "./img/img/d.png";
import im5 from "./img/img/5.png";
import im1 from "./img/img/1.png";
import im2 from "./img/img/c.png";
import background from "./img/flip.png";
import axios from "axios";
import ei3 from "./img/education-icon/3.png";
import ei1 from "./img/education-icon/1.png";
import ei2 from "./img/education-icon/2.png";
import ei4 from "./img/education-icon/4.png";
import ei5 from "./img/education-icon/5.png";
import ei6 from "./img/education-icon/6.png";
import ei7 from "./img/education-icon/7.png";
import ei8 from "./img/education-icon/8.png";
import ei9 from "./img/education-icon/9.png";
import Slider from "react-slick";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";

const Crowdfunding = () => {
  {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  const [userFullName, setUserFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");

  const [usermessage, setUsermessage] = useState("");

  const onChangeUserFullName = (event) => {
    setUserFullName(event.target.value);
  };

  const onChangeUserEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const onChangeUserPhone = (event) => {
    setUserPhone(event.target.value);
  };

  const onChangeUsermessage = (event) => {
    setUsermessage(event.target.value);
  };

  const onCreatePost = (event) => {
    event.preventDefault();
    const postData = {
      userFullName,
      userEmail,
      userPhone,

      usermessage,
    };
    axios
      .post("http://localhost:4000/app/signup", postData)
      .then((response) => {
        console.log(response);
      });
  };

  const form = useRef();

  const sendEmail = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_cdszuor",
        "template_1u3263l",
        form.current,
        "QDT9q3KiW_w51CvX3"
      )
      .then(
        (result) => {
          console.log(result.text);
          toastFunction();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  function toastFunction() {
    var x = document.getElementById("toast");
    x.className = "show";
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 1000);
    document.getElementById("myForm").reset();
  }
  var eduFlip = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    infinite: false,
    arrows: false,
    dots: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2500,
        },
      },

      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2500,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2500,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2500,
        },
      },
    ],
  };
  var causesSliderone = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <title>
          crowdfunding| Akoda SK foundation |Akoda fundraisers | Crowdfunding |
          Fundraising | Best Crowdfunding Platform | Fundraising Campaign |
          Hyderabad | India
        </title>
        <meta
          name="description"
          content=" Join Us To Educate Poor. Donate Today To Bring Smile On Thousands of Faces. We Will Bring Smile On Thousands of Faces When You Help Us."
        />
        <meta
          name="keywords"
          content="Akoda sk foundation ,Akoda fundraisers ,Best Crowdfunding Platform in Hyderabad, Fundraising Campaign in telangana , best fundraising platform for nonprofits, ngo-charity in Hyderabad , best charity in Hyderabad , Education Child fundraising,Orphan Service in Hyderabad telangana, Crowdfunding in telangana,Fundraising in Hyderabad ,Best Crowdfunding Platform telangana,  Hyderabad, India "
        />
      </Helmet>

      <section className="donation-area">
        <div className="fluid-container">
          <div className="donation-bg">
            <div className="row align-items-center">
              <div className="col-lg-8"></div>

              <div className="col-lg-4">
                <div
                  className="form "
                  style={{ backgroundColor: "rgb(189 220 237)" }}
                >
                  <h5 className="text-center">
                    Raising funds for <br />
                    <i
                      style={{
                        color: "rgb(240 136 33)",
                        textShadow: "2px 1px #d9d5d5",
                        fontSize: "25px !important",
                      
                      }}
                    >
                      {" "}
                      Education.{" "}
                    </i>
                  </h5>

                  <div className="input-container ic1">
                    <form
                      ref={form}
                      onSubmit={(event) => {
                        onCreatePost(event);
                        sendEmail(event);
                      }}
                      id="myForm" className="formMargin"
                    >
                      <label>Name</label>
                      <input
                        type="text"
                        name="user_name"
                        onChange={onChangeUserFullName}
                        value={userFullName}
                        required
                      />
                      <label>Email</label>
                      <input
                        type="email"
                        name="user_email"
                        onChange={onChangeUserEmail}
                        value={userEmail}
                        required
                      />
                      <label>Phone Number</label>
                      <input
                        type="number"
                        name="user_phone"
                        onChange={onChangeUserPhone}
                        value={userPhone}
                        required
                      />
                      <label>State</label>
                      <input
                        type="text"
                        name="user_country"
                        onChange={onChangeUsermessage}
                        value={usermessage}
                        required
                      />
                      {/* <label>Message</label>
                                              <textarea name="message" /> */}
                      <input 
                        type="submit"
                        value="Submit"
                        onClick={"toastFunction"}
                      />
                      <div id="toast">
                        <div className="checkicon">
                          {" "}
                          <i className="fas fa-check-square"></i>{" "}
                        </div>
                        SUCCESS: We have received your registration. Thanks!
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area about-style-three ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 ">
              <div className="about-img-3 mr-15 marg">
                <img src={im2} className="about-imgs-3" alt="Image" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content ml-15">
                <h4 style={{ color: '#0286CD' }}>How does Crowdfunding work on <span style={{ color: '#f08821' }}>AkoDa?</span> </h4>

                <p>
                  Let's say a person has a traffic accident. His medical bills
                  and hospital invoices are piled up. Now he needs 50,000 rupees
                  to pay his expensive medical expenses. Fortunately, his best
                  friend signed up for AkoDa's crowdfunding platform and
                  completed the process of submitting valid documents needed to
                  validate In minutes, he created a crowdfunding campaign to
                  raise money for his friend's medical expenses. Currently, this
                  campaign can be shared with all his dear via WhatsApp,
                  Instagram, Twitter, Facebook, and email. Funds will be
                  invested within minutes to meet the financial needs of an
                  injured friend.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area about-style-three">
        <div className="container">
          <div className="row align-items-center obu">
            <div className="col-lg-6">
              <div className="about-content ml-15">
                <h4 style={{ color: '#0286CD' }}>Why Akoda for an <span style={{ color: '#f08821' }}>Education Fundraiser?</span></h4>
                <p>
                  Akoda.org supports education for the underprivileged children,
                  as well as gives healthcare supports.{" "}
                </p>
                <p>
                  Fundraising through crowdfunding is an ideal way to support
                  this noble cause and Akoda is the best online fundraising
                  platform in Hyderabad, Telangana.
                </p>
                <p>
                  Akoda has been instrumental in bringing a positive change in
                  thousands of lives with the help of generous donors. At Akoda,
                  our committed efforts have transformed us into the best online
                  fundraising platform in Hyderabad. We have raised Rs. 100+
                  crores to pay for critical treatment of patients suffering
                  from cancer, heart-related diseases, thalassemia, transplants
                  and more, not to forget the covering of various educational
                  expenses like tuition fees, study materials etc. You can be an
                  active participant in this noble initiative too by
                  contributing to this fundraising campaign and also by sharing
                  this with family and friends, who can further strengthen this
                  crusade with their support.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-img-3 mr-15">
                <img src={im5} className="about-imgs-3" alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area about-style-three ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img-3 mr-15">
                <img src={im10} className="about-imgs-3" alt="Image" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content ml-15">
                <h4 style={{ color: '#f08821' }}>The Girl's education</h4>
                <p>
                  Some way or the other, through experience of self or other, we
                  have, most of us, come across the truth of the proverb, “If
                  you educate a man, you educate an individual.But, if you
                  educate a woman, you educate a nation”.
                </p>
                <p>
                  {" "}
                  Women education is important not only for women empowerment
                  but also has social and cultural benefits, such as, lower risk
                  of domestic violence, lower newborn mortality rates, quality
                  workforce, etc.
                </p>
                <p>
                  When you buy an extra pen for your own daughter, imagine how
                  many daughters of the nation are serving child labors when
                  they actually deserve going to school. You give her some
                  money, which might be snatched away from her, but you
                  contribute towards educating her, none can take that away.
                  Take a step towards it and we assure a giant leap as far as
                  the cause is concerned.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area about-style-three ptb-100">
        <div className="container">
          <div className="row align-items-center obu">
            <div className="col-lg-6">
              <div className="about-content ml-15">
                <h4 style={{ color: '#0286CD' }}>Taking care of <span style={{ color: '#f08821' }}>Orphans</span></h4>
                <p>
                  Ever been to an orphanage? It’s an oxymoronic experience;
                  empty and full at the same time. There are many little ones
                  out there who look at the privileged children, longingly and
                  naturally sometimes with envy.
                </p>
                <p>
                  How about wiping their tears, bringing smile on those chapped
                  lips, catering to nourishment, providing them with education
                  or at least helping the organizations that intend in doing so?
                  Helping those children can promote health, literacy, and
                  children’s empowerment, and thereby contribute to the making
                  of a healthier nation.
                </p>
                <p>
                  A bit of your good intent is all that we need to bring about
                  change. Help us to help them.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-img-3 mr-15">
                <img src={im1} className="about-imgs-3" alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row edu-flip box-container">
          <Slider {...eduFlip}>
            <div className="col-lg-3 box-item">
              <div className="flip-box">
                <div
                  className="flip-box-front text-center"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="inner color-white">
                    <img src={ei1} alt="Image" width="80" />
                    <h3 className="flip-box-header">Medical Fundraising</h3>
                  </div>
                </div>

                <div
                  className="flip-box-back text-center"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="inner color-white">
                    <h3 className="flip-box-header" >Medical Fundraising</h3>
                    <p>
                      Medical emergencies come without a warning.Health
                      insurance and savings are not enough to cover the
                      expensive bill.Crowdfunding helps you financially.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 box-item">
              <div className="flip-box">
                <div
                  className="flip-box-front text-center"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="inner color-white">
                    <img src={ei2} alt="Image" width="80" />
                    <h3 className="flip-box-header">Education Crowdfunding</h3>
                  </div>
                </div>

                <div
                  className="flip-box-back text-center"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="inner color-white">
                    <h3 className="flip-box-header">Education Crowdfunding</h3>
                    <p>
                      Akoda India’s Most Promising Online Crowdfunding Platform
                      to Raise Funds for Education
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 box-item">
              <div className="flip-box">
                <div
                  className="flip-box-front text-center"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="inner color-white">
                    <img src={ei3} alt="Image" width="80" />
                    <h3 className="flip-box-header">Animal Crowdfunding</h3>
                  </div>
                </div>

                <div
                  className="flip-box-back text-center"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="inner color-white">
                    <h3 className="flip-box-header">Animal Crowdfunding</h3>
                    <p>
                      Animal Conservation Organisations receive donations online
                      through Akoda’s Online Crowdfunding Platform for Animal
                      Fundraising.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 box-item">
              <div className="flip-box">
                <div
                  className="flip-box-front text-center"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="inner color-white">
                    <img src={ei4} alt="Image" width="80" />
                    <h3 className="flip-box-header">Child Welfare Support</h3>
                  </div>
                </div>

                <div
                  className="flip-box-back text-center"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="inner color-white">
                    <h3 className="flip-box-header">Child Welfare Support</h3>
                    <p>
                      Akoda's online crowdfunding platform to raise funds for
                      child welfare
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 box-item">
              <div className="flip-box">
                <div
                  className="flip-box-front text-center"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="inner color-white">
                    <img src={ei5} alt="Image" width="80" />
                    <h3 className="flip-box-header">
                      Extracurricular Programs
                    </h3>
                  </div>
                </div>

                <div
                  className="flip-box-back text-center"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="inner color-white">
                    <h3 className="flip-box-header">
                      Extracurricular Programs
                    </h3>
                    <p>
                      Don't miss out on this opportunity to raise more money for
                      excellence through Crowdfinancing!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>

      <section className="our-mission-area pt-100">
        <div className="container">
          <div className="section-title section-white-title section-title-three">
            <span className="top-title"> SERVICES </span>

            <h2 style={{ color: "#000" }}>
              Akoda stand out among other online crowdfunding platforms for
              raising funds
            </h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 service-serv">
              <div className="single-mission style-three">
                <img src={ei6} alt="Image" width="70" />
                <span>01</span>
                <h3>Payment</h3>
                <p>
                  No need to wait to receive funds. Akoda ensures that the
                  collected funds can be withdrawn at any time during the
                  fundraising process.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 service-serv">
              <div className="single-mission style-three">
                <img src={ei7} alt="Image" width="70" />
                <span>02</span>
                <h3>Providing international payment support</h3>
                <p>
                  We accept donations in multiple currencies from around the
                  world, so you can reach out to like-minded students and peers
                  abroad.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 service-serv">
              <div className="single-mission style-three">
                <img src={ei8} alt="Image" width="70" />
                <span>03</span>
                <h3>24*7 Support</h3>
                <p>
                  With 24x7 on-call assistance, WhatsApp, Email, SMS, and a
                  personal chat interface, there is always someone around to
                  assist you.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 service-serv">
              <div className="single-mission style-three">
                <img src={ei9} alt="Image" width="70" />
                <span>04</span>
                <h3>Fundraising expert dedicated to raising funds</h3>
                <p>
                  A dedicated fundraiser expert is assigned to you; he or she
                  will guide you through your fundraising journey and answer all
                  your fundraising questions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>




    </div>
  );
};

export default Crowdfunding;
