import React from 'react'
import eventsOne from '../Component/EventImages/1.png'
import siren from './assets/images/events/siren.png'
import akodaForChild from '../Component/EventImages/akodaForChild.jpg'
import facebook from './assets/images/events/facebook.png'
import { Link } from 'react-router-dom'


const EventsOne = () => {

    { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
      <div>
      

            <div className="section-title section-title-left" >
                <img src={siren} style={{ width: '30px', height: '30px' }} alt="Image" />
                <span className="top-title">This fundraiser is in an urgent need of funds</span><br />
                <span className="container" style={{ color: 'rgb(17, 15, 15)', fontSize: '32px' }}>
                Fundraise with AkoDa Foundation</span>
            </div>


            {/* <!-- Start Event Details Page Area --> */}
            
            <section className="evet-details-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="event-details-content ">
                                <div className="event-details-img">
                                    <img src={eventsOne} alt="Image" />
                                    <span className="date">07 <br /> Aug</span>
                                </div>

                                <div className="event-top-content">
                                    <div className="event-content">
                                     
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div className="tab-content">
                                                    <h4>Description</h4>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                        Dignissimos, accusantium autem blanditiis recusandae ratione
                                                        architecto alias veniam nesciunt animi praesentium perferendis
                                                        iure dolorum nihil error ad, facere optio magni tenetur
                                                        molestiae eaque odit. Fugiat corporis, tempore molestias eum
                                                        suscipit laudantium culpa non unde dolor aut, quas aperiam illum
                                                        ullam ratione.</p>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"> <div className="tab-content">
                                                <h4>Vision</h4>
                                                <p>Vel, ea praesentium sapiente excepturi consequuntur hic
                                                    aspernatur, temporibus delectus dolores numquam ratione vero
                                                    itaque ducimus ipsam adipisci beatae blanditiis corrupti
                                                    veritatis?Lorem ipsum dolor sit amet consectetur adipisicing
                                                    elit. Necessitatibus culpa molestiae quaerat nemo. </p>
                                            </div></div>
                                            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                                <div className="tab-content" >
                                                    <h4>Goals</h4>
                                                    <p>Necessitatibus culpa molestiae quaerat nemo.Vel, ea praesentium
                                                        sapiente excepturi consequuntur hic aspernatur, temporibus
                                                        delectus dolores numquam ratione vero itaque ducimus ipsam
                                                        adipisci beatae blanditiis corrupti veritatis?Lorem ipsum dolor
                                                        sit amet consectetur ea praesentium sapiente excepturi
                                                        consequuntur hic aspernatur, temporibus delectus dolores numquam
                                                        ratione adipisicing elit. </p>
                                                </div>
                                            </div>
                                        </div>


                                     



                                        <h3>Education for poor children</h3>
                                        <p>Nulla quis lorem ut libero malesuada feugiat. Sed porttitor lectus nibh. Quisque
                                            velit nisi, pretium ut lacinia in, elementum id enim. Praesent sapien massa,
                                            convallis a pellentesque nec, egestas non nisi. Proin eget tortor risus.
                                            Curabitur
                                            aliquet quam id dui posuere blandit. Proin eget tortor risus. Vestibulum ac diam
                                            sit
                                            amet quam vehicula elementum sed sit amet dui. Praesent sapien massa, convallis
                                            a
                                            pellentesque nec, egestas non nisi. Quisque velit nisi, pretium ut lacinia in,
                                            elementum id enim. Nulla quis lorem ut libero malesuada feugiat. Mauris blandit
                                            aliquet elit.</p>

                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla porttitor accumsan
                                            tincidunt. Proin eget tortor risus. Vestibulum ac diam sit amet quam vehicula
                                            elementum sed sit amet dui. Curabitur non nulla sit amet nisl tempus convallis
                                            quis
                                            ac lectus. Sed porttitor lectus nibh. Nulla quis lorem ut libero malesuada
                                            feugiat.
                                            Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur
                                            aliquet
                                            quam id dui posuere blandit. Praesent sapien massa, convallis a pellentesque
                                            nec,
                                            egestas non nisi. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a
                                            Curabitur</p>

                                        <div className="gap-mb-20"></div>

                                        <h3>Event mission</h3>
                                        <p>Nulla quis lorem ut libero malesuada feugiat. Sed porttitor lectus nibh. Quisque
                                            velit nisi, pretium ut lacinia in, elementum id enim. Praesent sapien massa,
                                            convallis a pellentesque nec, egestas non nisi. Proin eget tortor risus.
                                            Curabitur
                                            aliquet quam id dui posuere blandit. Proin eget tortor risus. Vestibulum ac diam
                                            sit
                                            amet quam vehicula elementum sed sit amet dui. Praesent sapien massa, convallis
                                            a
                                            pellentesque nec, egestas non nisi</p>

                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla porttitor accumsan
                                            tincidunt. Proin eget tortor risus. Vestibulum ac diam sit amet quam vehicula
                                            elementum sed sit amet dui. Curabitur non nulla sit amet nisl tempus convallis
                                            quis
                                            ac lectus. Sed porttitor lectus nibh. Nulla quis lorem ut libero malesuada
                                            feugiat.
                                        </p>

                                        <div className="gap-mb-20"></div>

                                   
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-4">
                            <div className="widget-sidebar ml-15">
                                <div className="sidebar-widget mt-0" >
                                    <form className="choose-us-from-bg">


                                        <div className="others-options" style={{ padding: '2px', paddingBottom: '37px' }}>
                                            <ul>
                                                <li>
                                                    <Link to="/donate-now" className="default-btn border-radius-btn"
                                                        style={{ padding: '15px', fontSize: '20px', marginTop: '-25px', borderRadius: '0px' }}>
                                                        Donate Now
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <a href="https://www.facebook.com/profile.php?id=100079026490521" target="_blank">
                                            <div className="others-options"
                                                style={{ border: '2px solid #344E86', padding: '18px 15px 15px 15px', borderRadius: '10px', backgroundColor: '#344E86', paddingLeft: '43px' }}>
                                                <img src={facebook} height="25px" width="25px" alt="" />
                                                <span
                                                    style={{ fontWeight: 'bold', color: 'rgb(255, 248, 248)', paddingLeft: '10px' }}>Spread The Word</span>
                                            </div>
                                        </a>


                                    </form>
                                </div>

                                <div className="text-center">
                                        <img src={akodaForChild} alt="" style={{height:'300px',textAlign:'center'}}/>
                                        </div>

                              

                                <div className="sidebar-widget">
                                    <h3>Event location</h3>

                                    <div className="map">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.390700423798!2d78.3803008153709!3d17.441004405846062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e1bef1173f%3A0xedf7c46288fdf31a!2sKTC%20Illumination%2C%20Vittal%20Rao%20Nagar%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1663236626782!5m2!1sen!2sin"></iframe>
                                         </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </div>
  )
}

export default EventsOne