import React from 'react'

import gallery1 from './assets/images/gallery/gallery-1.jpg'
import gallery2 from './assets/images/gallery/gallery-2.jpg'
import gallery3 from './assets/images/gallery/gallery-3.jpg'
import gallery4 from './assets/images/gallery/gallery-4.jpg'
import gallery5 from './assets/images/gallery/gallery-5.jpg'
import gallery6 from './assets/images/gallery/gallery-6.jpg'
import gallery7 from './assets/images/gallery/gallery-7.jpg'
import gallery8 from './assets/images/gallery/gallery-8.jpg'
import gallery9 from './assets/images/gallery/gallery-9.jpg'
import gallery10 from './assets/images/gallery/gallery-10.jpg'
import gallery11 from './assets/images/gallery/gallery-11.jpg'
import gallery12 from './assets/images/gallery/gallery-12.jpg'
import akoda1 from './assets/images/gallery/akoda-1.jpeg'
import akoda2 from './assets/images/gallery/akoda2.jpeg'
import akoda3 from './assets/images/gallery/akoda3.jpeg'
import akoda4 from './assets/images/gallery/akoda4.jpeg'
import akoda5 from './assets/images/gallery/akoda5.jpeg'
import akoda6 from './assets/images/gallery/akoda6.jpeg'
import akoda7 from './assets/images/gallery/akoda7.jpeg'
import akoda8 from './assets/images/gallery/akoda8.jpeg'
import akoda9 from './assets/images/gallery/akoda9.jpeg'
import akoda10 from './assets/images/gallery/akoda10.jpeg'
import akoda11 from './assets/images/gallery/akoda11.jpeg'
import akoda12 from './assets/images/gallery/akoda12.jpeg'
import akoda13 from './assets/images/gallery/akoda13.jpeg'
import akoda14 from './assets/images/gallery/akoda14.jpeg'
import akoda15 from './assets/images/gallery/akoda15.jpeg'
import akoda16 from './assets/images/gallery/akoda16.jpeg'
import img1 from './assets/images/gallery/img/1.JPG'
import img2 from './assets/images/gallery/img/3.JPG'
import img3 from './assets/images/gallery/img/4.JPG'
import img4 from './assets/images/gallery/img/5.JPG'
import img5 from './assets/images/gallery/img/7.JPG'
import img6 from './assets/images/gallery/img/6.JPG'
import img7 from './assets/images/gallery/img/2.JPG'

import bdy1 from './assets/images/gallery/bdy/bday1.jpeg'
import bdy2 from './assets/images/gallery/bdy/bday2.jpeg'
import bdy3 from './assets/images/gallery/bdy/bday3.jpeg'
import bdy4 from './assets/images/gallery/bdy/bday4.jpeg'
import bdy5 from './assets/images/gallery/bdy/bday5.jpeg'
import bdy6 from './assets/images/gallery/bdy/bday6.jpeg'
import bdy7 from './assets/images/gallery/bdy/bday7.jpeg'
import bdy8 from './assets/images/gallery/bdy/bday8.jpeg'


import im1 from './img/img/1.png'
import { Link } from 'react-router-dom'



const Gallery = () => {
	{ window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>
    
      {/* <!-- Search Modal --> */}
      <div className="modal fade search-modal-area" id="exampleModalsrc">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button type="button" data-bs-dismiss="modal" className="closer-btn">
              <i className="ri-close-line"></i>
            </button>
            <div className="modal-body">
              <form className="search-box">
                <div className="search-input">
                  <input type="text" name="Search" placeholder="Search for..." className="form-control" />
                  <button type="submit" className="search-btn">
                    <i className="ri-search-line"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Search Modal --> */}

      {/* <!-- Start Page Title Area --> */}
      <div className="page-title-area bg-1">
        <div className="container">
          <div className="page-title-content">
            <h2> Gallery </h2>

            <ul>
              <li>
                <Link to="/">
                  Home
                </Link>
              </li>

              <li className="active"> Gallery  </li>
            </ul>
          </div>
        </div>


      </div>
      {/* <!-- End Page Title Area --> */}

      {/* <!-- Start About Area --> */}
      {/* <section className="about-area about-style-three ptb-100">
                <div className="container">
                    <div className="row align-items-center obu">
                        <div className="col-lg-6">
                            <div className="about-content ml-15">
                                <h4>A Collage of Empowerment</h4>
                                <p>Providing the down-trodden children with good health and education are among the prime works of Akoda. 
                                  We expect you to help us create a better picture of the world.</p>
                                <p>A human should stand up for another human. And that noble gesture should go into the annals of 
                                  history of posterity. Today as a civil society we should come together to empower the underprivileged.</p>
                                <p>The best path of empowering children is through good health and education. Let’s support the next 
                                  generation by fostering Akoda fundraisers using crowdfunding and donation for the cause, and be a part of 
                                  the history that has made a positive difference.</p>
                                  
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-img-3 mr-15">
                                <img src={im1} className="about-imgs-3" alt="Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section id="gallery" >
				<div >
					<div id="image-gallery">
						<div className="section-title">
							<span className="top-title">OUR ACTIVITIES</span>
							<h2>Sample images of some recent work are highlighted</h2>
						</div>
						 <div class="row ">

						 <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={bdy1}><img src={bdy1} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={bdy2}><img src={bdy2} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={bdy3}><img src={bdy3} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={bdy4}><img src={bdy4} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={bdy5}><img src={bdy5} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={bdy6}><img src={bdy6} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={bdy7}><img src={bdy7} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={bdy8}><img src={bdy8} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>



							
						 <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={img1}><img src={img1} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={img2}><img src={img2} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={img3}><img src={img3} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={img4}><img src={img4} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={img5}><img src={img5} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={img6}><img src={img6} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={img7}><img src={img7} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
						
							 

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda2}><img src={akoda2} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda3}><img src={akoda3} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda4}><img src={akoda4} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda5}><img src={akoda5} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda6}><img src={akoda6} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda7}><img src={akoda7} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda8}><img src={akoda8} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda10}><img src={akoda10} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda11}><img src={akoda11} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda13}><img src={akoda13} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda15}><img src={akoda15} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda16}><img src={akoda16} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={gallery1}><img src={gallery1} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery2}><img src={gallery2} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery3}><img src={gallery3} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery4}><img src={gallery4} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery5}><img src={gallery5} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery6}><img src={gallery6} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery7}><img src={gallery7} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery8}><img src={gallery8} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery9}><img src={gallery9} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery11}><img src={gallery11} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery12}><img src={gallery12} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

						</div> 

					</div>
				</div>

			</section>

     
   
    </div>
  )
}

export default Gallery