import React, { useState } from 'react'
import Slider from 'react-slick'
import Arr from './MenuItems'
import { Link } from 'react-router-dom'

const OurCauses = () => {
	const [items, setItems] = useState(Arr);
	const filterItem =(categItem) =>{
		const updatedItems =Arr.filter((curElem)=>{
			return curElem.catogaries === categItem;
		});
		setItems(updatedItems);

	}
	var ourCause = {
		slidesToShow: 3,
		slidesToScroll: 1,
		Arrows: false,
		autoplay: true,
		infinite: true,
		Arrows: false,
		dots: false,
		lazyLoad: 'ondemand',
		slide: '.slide',
		autoplaySpeed: 2000,
		speed: 1000,
		cssEase: 'linear',
		adaptiveHeight: true,
		swipe: true,
		swipeToSlide: true,


		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					autoplay: true,
					lazyLoad: 'ondemand',
					slide: '.slide',
					autoplaySpeed: 2000,
					speed: 1000,
					cssEase: 'linear',
					adaptiveHeight: true,
					swipe: true,
					swipeToSlide: true,


				}
			},

			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					autoplay: true,
					dots: false,
					Arrows: false,
					lazyLoad: 'ondemand',
					slide: '.slide',
					autoplaySpeed: 2000,
					speed: 1000,
					cssEase: 'linear',
					adaptiveHeight: true,
					swipe: true,
					swipeToSlide: true,

				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false,
					autoplay: true,
					Arrows: false,
					autoplaySpeed: 3000,
					infinite: true,
					lazyLoad: 'ondemand',
					slide: '.slide',
					autoplaySpeed: 2000,
					speed: 1000,
					cssEase: 'linear',
					adaptiveHeight: true,
					swipe: true,
					swipeToSlide: true,

				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					Arrows: false,
					autoplay: true,
					autoplaySpeed: 3000,
					infinite: true,
					lazyLoad: 'ondemand',
					slide: '.slide',
					autoplaySpeed: 2000,
					speed: 1000,
					cssEase: 'linear',
					adaptiveHeight: true,
					swipe: true,
					swipeToSlide: true,
				}
			}
		]
	};


	return (
		<div>
			<section className="py-5 header cause-system">
				<div className="container " style={{height:'550px !important'}}>
					<div className="section-title section-title-left">
						{/* <span className="top-title">OUR Events</span> */}
						<h2>Trending Fundraisers</h2>
					</div>
					<div className="row">
						<div className="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
							{/* <!-- Tabs nav --> */}
							<div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
								aria-orientation="vertical" style={{ paddingRight: '30px' }}>							
									<a className="nav-link mb-3 shadow active" id="v-pills-home-tab" data-toggle="pill"
										href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"  onClick={() => setItems(Arr)}>
									{/* <i className="fa fa-star mr-2"></i> */}
									<span  className="font-weight-bold small text-uppercase">All Categories</span>		
									</a>
									<a className="nav-link mb-3 shadow" id="v-pills-profile-tab" data-toggle="pill"
										href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => filterItem('Education')}>
										{/* <i className="fa fa-star mr-2"></i> */}
										<span className="font-weight-bold small text-uppercase">Education</span>
									</a>
									<a className="nav-link mb-3 shadow" id="v-pills-messages-tab" data-toggle="pill"
										href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false" onClick={() => filterItem('Medical')}>
										{/* <i className="fa fa-star mr-2"></i> */}
										<span className="font-weight-bold small text-uppercase">Medical</span>
									</a>
									<a className="nav-link mb-3 shadow" id="v-pills-settings-tab" data-toggle="pill"
										href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false" onClick={() => filterItem('Children')}>
										{/* <i className="fa fa-star mr-2"></i> */}
										<span className="font-weight-bold small text-uppercase">Children</span>
									</a>
									<a className="nav-link mb-3 shadow" id="v-pills-env-tab" data-toggle="pill"
										href="#v-pills-env" role="tab" aria-controls="v-pills-env" aria-selected="false" onClick={() => filterItem('Environment')}>
										{/* <i className="fa fa-star mr-2"></i> */}
										<span className="font-weight-bold small text-uppercase">Environment</span>
									</a>
									<a className="nav-link mb-3 shadow" id="v-pills-Orphan-tab" data-toggle="pill"
										href="#v-pills-Orphan" role="tab" aria-controls="v-pills-Orphan" aria-selected="false" onClick={() => filterItem('Orphan')}>
										{/* <i className="fa fa-star mr-2"></i> */}
										<span className="font-weight-bold small text-uppercase">Orphan</span>
									</a>
							</div>
						</div>

						<div className="col col-lg-9 col-md-9 col-sm-9 col-xs-9">
							{/* <!-- Tabs content --> */}
							<div className="tab-content bigSc" id="v-pills-tabContent">
								<div className="tab-pane fade  rounded  show active " id="v-pills-home" role="tabpanel"
									aria-labelledby="v-pills-home-tab" style={{ paddingLeft: '30px' }}>
									<div className="container"  >
										{/* <div className="owl-carousel"> */}
										<Slider {...ourCause}>
											{
													items.map((elem) => {
													const { id, Name, title, image, descriptions, price,to } = elem;
													return (
														<div className="single-causes style-two" key={id} >
															<div className="causes-img">
																<Link to={to} >
																	<img src={image} alt="Image"
																		className="height-img" />
																	<span className="tag">{Name}</span>
																</Link>
															</div>
															<div className="bar-1">
																<h3>
																	<Link to={to}>
																		{title}
																	</Link>
																</h3>
																<p>{descriptions}</p>
																
																	<div className="goal"><span>Goal:</span>{price}</div>
																
																<ul className="donate-read">
																	<li>
																		<Link to={to} className="default-btn">
																			Donate Now
																		</Link>
																	</li>
																</ul>
															</div>
														</div>
													)
												})
											}
										</Slider>
										{/* </div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="container my-3 cause-mobile">
				<div className="card">
					<div className="card-header">
						<div className="d-flex">
							<div className="section-title">
								{/* <span className="top-title">OUR CAUSES</span> */}
								<h2>Trending Fundraisers</h2>
							</div>
						</div>

						{/* <!-- START TABS DIV --> */}
						<div className="tabbable-responsive">
							<div className="tabbable">
								<div className="dropdown">
									<button className="btn btn-secondary dropdown-toggle filter-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"  onClick={() => setItems(Arr)}>
										All Categories
									</button>
									<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" id="myTab" role="tablist">
										<li className="nav-item">
											<a className="nav-link" id="second-tab" data-toggle="tab" href="#second" role="tab"
												aria-controls="second" aria-selected="false" onClick={() => filterItem('Education')}>Education</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="third-tab" data-toggle="tab" href="#third" role="tab"
												aria-controls="third" aria-selected="false" onClick={() => filterItem('Medical')}>Medical</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="fourth-tab" data-toggle="tab" href="#fourth" role="tab"
												aria-controls="fourth" aria-selected="false" onClick={() => filterItem('Children')}>Children</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="sixth-tab" data-toggle="tab" href="#sixth" role="tab"
												aria-controls="sixth" aria-selected="false" onClick={() => filterItem('Environment')}>Environment</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="fifth-tab" data-toggle="tab" href="#fifth" role="tab"
												aria-controls="fifth" aria-selected="false" onClick={() => filterItem('Orphan')}>Orphan</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="card-body">
						<div className="tab-content">
							<div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
								<div className="container">
									<div className="row">
										<Slider {...ourCause}>
											{
												items.map((elem) => {
													const { id, Name, title, image, descriptions, price,to } = elem;
													return (
														<div className="single-causes style-two" key={id} style={{height:'500px !important'}}>
															<div className="causes-img">
															<Link to={to} >
																	<img src={image} alt="Image"
																		className="height-img"/>
																	<span className="tag">{Name}</span>
																</Link>
															</div>
															<div className="bar-1">
																<h3>
																<Link to={to} >
																		{title}
																	</Link>
																</h3>
																<p>{descriptions}</p>
																<div><ul className="total-donate">
																	{/* <li><span>Raised: </span>$8,000.00</li> */}
																	<li className="goal"><span>Goal:</span>{price}</li>
																</ul></div>
 																
																<ul className="donate-read">
																	<li>
																		<Link to={to} className="default-btn">
																			Donate Now
																		</Link>

																	</li>
																</ul>
															</div>
														</div>
													)
												})
											}

										</Slider>
									</div>
								</div>
							</div>
						</div>     	
						{/* <!-- END TABS DIV --> */}
					</div>
				</div>
			</div>
		</div >
	)

}

export default OurCauses