import React from 'react'

const SignIn = () => {
  return (
    <div>
      <div className="wrapper">
        {/* <!--  Header  --> */}
        <header className="section header">
          <div className="trapezoid"></div>
          <div className="header__text">
            <h1 style={{color:'#fff'}}>SignIn.</h1>
            <p>Sign in or create a new account.</p>
          </div>
        </header>
        {/* <!--  Sign Up  --> */}
        <section className="section sign-up">
          <div className="trapezoid"></div>
          <form action="" className="signUp">
            <input type="text" name="name" placeholder="Name" />
            <input type="text" name="email" placeholder="Email" />
            <input type="number" name="phone" placeholder="phone" />

            <input type="password" name="password" placeholder="Password" />
            <input type="password" name="confirm" placeholder="Confirm Password" Validate/>
            <button>Create Account</button>
            <p className="opposite-btn2">Already have an account?</p>
          </form>
        </section>
        {/* <!--  Sign In  --> */}
        <section className="section sign-in">
          <form action="" className="signIn">
            <input type="text" name="email" placeholder="Email" />
            <input type="password" name="password" placeholder="Password" />
            <button>Sign In</button>
            <p className="opposite-btn1">Don't have an account?</p>
          </form>
        </section>
      </div>
    </div>
  )
}

export default SignIn