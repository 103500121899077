import React from 'react'
// import event4 from '../Component/EventImages/4.png'
// import siren from './assets/images/events/siren.png'
import feedingPoor from '../Component/EventImages/feedingPoor.jpg'
import facebook from './assets/images/events/facebook.png'
import { Link } from 'react-router-dom'
import akoda1 from './assets/images/gallery/18.JPG'
const Oldagefoodevent = () => {
  return (
    <div>

<div className="section-title section-title-left" >
                {/* <img src={siren} style={{ width: '30px', height: '30px' }} alt="Image" /> */}
                {/* <span className="top-title"> Akoda Enent One  </span><br /> */}
                <span className="container" style={{ color: 'rgb(17, 15, 15)', fontSize: '32px' }}>
                 Food donation for Old age Home </span>
            </div>


        
            
            <section className="evet-details-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="event-details-content ">
                                <div className="event-details-img">
                                    <img src={akoda1} alt="Image" />
                                    <span className="date">29 <br /> FEB</span>
                                </div>

                                <div className="event-top-content">
                                    <div className="event-content" style={{textAlign:'justify'}}>
                                       


                                        <p> Annadanam, or the act of providing food to those in need, is a noble and compassionate gesture. When planning annadanam for an old age home, it's important to consider nutritious and easily digestible meals that cater to the dietary needs and preferences of the elderly residents.</p>
                                        <p>When organizing annadanam, it's advisable to consult with the staff or management of the old age home to ensure that the menu meets the residents' dietary requirements and any cultural or religious considerations. Additionally, consider incorporating seasonal and locally available ingredients to enhance the freshness and nutritional value of the meals. Your thoughtful contribution will undoubtedly be appreciated by the elderly residents and bring comfort and nourishment to their lives.</p>
                                        

                                        <div className="gap-mb-20"></div>

                                      
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-4">
                            <div className="widget-sidebar ml-15">
                                <div className="sidebar-widget mt-0" >
                                    <form className="choose-us-from-bg">


                                        <div className="others-options" style={{ padding: '2px', paddingBottom: '37px' }}>
                                            <ul>
                                                <li>
                                                    <Link to="/donate-now" className="default-btn border-radius-btn"
                                                        style={{ padding: '15px', fontSize: '20px', marginTop: '-25px', borderRadius: '0px' }}>
                                                        Donate Now
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <a href="https://www.facebook.com/profile.php?id=100079026490521" target="_blank">
                                            <div className="others-options"
                                                style={{ border: '2px solid #344E86', padding: '18px 15px 15px 15px', borderRadius: '10px', backgroundColor: '#344E86', paddingLeft: '43px' }}>
                                                <img src={facebook} height="25px" width="25px" alt="" />
                                                <span
                                                    style={{ fontWeight: 'bold', color: 'rgb(255, 248, 248)', paddingLeft: '10px' }}>Spread The Word</span>
                                            </div>
                                        </a>


                                    </form>
                                </div>


                                            <div className="text-center">
                                        <img src={feedingPoor} alt="" style={{height:'300px',textAlign:'center'}}/>
                                        </div>
                                <div className="sidebar-widget">
                                    <h3>Event location</h3>

                                    <div className="map">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.390700423798!2d78.3803008153709!3d17.441004405846062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e1bef1173f%3A0xedf7c46288fdf31a!2sKTC%20Illumination%2C%20Vittal%20Rao%20Nagar%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1663236626782!5m2!1sen!2sin"></iframe>
                                         </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    </div>
  )
}

export default Oldagefoodevent