import React from 'react'
import testimonial1 from './assets/images/testimonial/testimonial-1.jpg'
import testimonial2 from './assets/images/testimonial/testimonial-2.jpg'
import testimonial3 from './assets/images/testimonial/testimonial-3.jpg'
import testimonial4 from './assets/images/testimonial/testimonial-4.png'

import Slider from 'react-slick'
const Testimonial = () => {
    var childPortfolio = {
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		infinite: true,
		arrows: true,
		dots: false,

		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				}
			}
		]
	};
  return (
    <div>

{/* <!-- Start Testimonial Area --> */}
<section className="testimonial-area pt-100 pb-100 " >
				<div className="container ">
					<div className="section-title">
						<span className="top-title">TESTIMONIALS</span>
						<h2>What clients say about our performance</h2>
					</div>

					<div className="child-portfolio">
						<Slider {...childPortfolio}>
							<div className="single-testimonial-content">
								<div className="testimonial-avatar">
									<img src={testimonial1} className="avatar" alt="Image" />
									{/* <!-- <img src="assets/images/testimonial/quat.png" className="quat" alt="Image"> --> */}
									<h3>Pranay Thakur</h3>
									{/* <span>Founder & CEO</span> */}
								</div>

								<p>“My experience with Akoda was very authentic. I donated for the purpose of child education 2 months back. The transaction procedure was transparent and smooth. Great work Akoda! Keep it up!”</p>

								<ul>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
								</ul>
							</div>

							<div className="single-testimonial-content">
								<div className="testimonial-avatar">
									<img src={testimonial3} className="avatar" alt="Image" />
									{/* <!-- <img src="assets/images/testimonial/quat.png" className="quat" alt="Image"> --> */}
									<h3>Hridesh Kumar</h3>
									{/* <span>Company Manager</span> */}
								</div>

								<p>“Akoda provides you with enough proof so that you know where your donation is actually going, whether it is reaching the needy or not. In my experience, Akoda is absolutely genuine and I look forward to help their noble cause as much as I can in future as well.”</p>

								<ul>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
								</ul>
							</div>
                            <div className="single-testimonial-content">
								<div className="testimonial-avatar">
									<img src={testimonial4} className="avatar" alt="Image" />
									{/* <!-- <img src="assets/images/testimonial/quat.png" className="quat" alt="Image"> --> */}
									<h3>Advika Akhtar</h3>
									{/* <span>Company Manager</span> */}
								</div>

								<p>“Some of my friends and I made a group to help children of a small village in Telengana in their education, beginning with clearing their tuition fees, buying books and notebooks etc.Akoda crowdfunding helped us address our cause perfectly.”</p>

								<ul>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
								</ul>
							</div>
                            <div className="single-testimonial-content">
								<div className="testimonial-avatar">
									<img src={testimonial2} className="avatar" alt="Image" />
									{/* <!-- <img src="assets/images/testimonial/quat.png" className="quat" alt="Image"> --> */}
									<h3>Tiyasha Sen</h3>
									{/* <span>Company Manager</span> */}
								</div>

								<p>“Having had a bad experience previously with one such other organization, in the beginning I was doubtful about the credibility of this NGO. However, I donated and eventually I went to the particular child’s family and found them actually smiling. I thank Akoda for their support and clarity. I feel great having been able to be someone’s cause of smile. This could not be possible without Akoda.”</p>

								<ul>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
								</ul>
							</div>
                            <div className="single-testimonial-content">
								<div className="testimonial-avatar">
									<img src={testimonial2} className="avatar" alt="Image" />
									{/* <!-- <img src="assets/images/testimonial/quat.png" className="quat" alt="Image"> --> */}
									<h3>Monic Barbosa</h3>
									{/* <span>Company Manager</span> */}
								</div>

								<p>“Akoda was a 100% genuine experience for me. Moreover, the way they appreciated my contribution was really great. It made me feel not like an outsider but a part of the Akoda family itself.  ”</p>

								<ul>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
									<li>
										<i className="ri-star-fill"></i>
									</li>
								</ul>
							</div>
						</Slider>
					</div>
				</div>
			</section>
			{/* <!-- End Testimonial Area --> */}

    </div>
  )
}

export default Testimonial